import 'react-calendar/dist/Calendar.css';
import React, { useContext, useEffect, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import RelationsSelect from './RelationsSelect';
import Api from './Api';
import RelationCard from './RelationCard';
import { CloseIcon, CompanyIcon, IndividualIcon, MattersIcon, NgoIcon, TasksIcon, ThreeDotsMenu } from './Icons';
import { useNavigate } from 'react-router-dom';
import { getIndex } from '../Helpers';

export default function LitigationRelations(props) {

    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const { data, setData, litigationId } = props
    const [generalData,] = generalDataGlobal;
    const [authUser] = authUserGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [relationPopup, setRelationPopup] = useState(false);
    const [currentRelation, setCurrentRelation] = useState(null);
    const [description, setDescription] = useState('');
    const [editRelationMenu, setEditRelationMenu] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);
    const [clientType, setClientType] = useState(2);
    const [updateProf, setUpdateProf] = useState(0);
    const [selectedRelation, setSelectedRelation] = useState('');
    const navigate = useNavigate();

    // close relation popup and reset states
    const closeAddRelation = (e) => {
        setRelationPopup(false);
        setErrorsReturned({});
        setCurrentRelation(null);
        setDescription('');
        setEditRelationMenu(null);
        setDeletePopup(null);
        setSelectedRelation('');
    }

    //get all relations from general data
    const allRelations = () => {
        const relOptions = data?.relation_names?.map(
            singleRel => ({ value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.type }))
        return relOptions;
    }

    //get all client types
    const clientTypes = () => {
        const clientTy = generalData?.client_types?.map(
            singleType => ({ value: singleType.id, label: singleType.title }))
        return clientTy;
    }

    // cancel delete
    const cancelDeletePopup = (e) => {
        setDeletePopup(null);
        setEditRelationMenu(null);
        closeAddRelation(e);
    }

    // set states when editing a relation
    const editRelationClick = (e, data) => {
        if (data?.user?.id) {
            let text = 'user-' + data?.user.id;
            setSelectedRelation(allRelations()[getIndex(text, allRelations(), 'value')]);
        }
        if (data?.relation?.id) {
            let text = 'client-' + data?.relation.id;
            setSelectedRelation(allRelations()[getIndex(text, allRelations(), 'value')]);
        }
        if (data?.linked_litigation) {
            let text = 'litigation-' + data?.linked_litigation.id;
            setSelectedRelation(allRelations()[getIndex(text, allRelations(), 'value')]);
        }
        if (data?.linked_task) {
            let text = 'task-' + data?.linked_task_id;
            setSelectedRelation(allRelations()[getIndex(text, allRelations(), 'value')]);
        }
        setDescription(data?.description ? data?.description : '');
        setCurrentRelation(data);
        setRelationPopup(true);
    }

    // delete element 
    const deleteElement = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('user_id', props.user_id);
        fd.append('client_id', props.user_id);
        fd.append('client_type_id', clientType);
        fd.append('linked_litigation', Number(props?.linked_litigation) || '')
        fd.append('litigation_id', Number(props?.litigationId) || '')
        fd.append('return_client', props?.returnClient || 0)

        if (deletePopup && deletePopup?.id) {
            fd.append('relation_id', deletePopup?.id);
        }

        setGeneralDataApiOptions({
            url: 'delete-relation',
            method: 'post',
            data: fd,
            callback: res => {
                closeAddRelation(e);
                updateRelation(res);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    // submit relation 
    const submitRelation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('relation_name', selectedRelation ? selectedRelation?.value : '');
        fd.append('user_id', props.user_id || '');
        fd.append('client_id', props.user_id || '');
        fd.append('litigation_id', Number(litigationId) || '')
        fd.append('linked_litigation', ((selectedRelation?.client_type === 'litigation') || (selectedRelation?.type === 'litigation')) ? Number(selectedRelation?.value.split('-')[1]) : '')
        fd.append('linked_task', ((selectedRelation?.client_type === 'task') || (selectedRelation?.type === 'task')) ? Number(selectedRelation?.value.split('-')[1]) : '')
        fd.append('description', description);
        fd.append('client_type_id', clientType);
        fd.append('return_client', props?.returnClient || 0);
        fd.append('throwError', true);

        if (currentRelation && currentRelation?.id) {
            fd.append('relation_id', currentRelation?.id);
        }
        setGeneralDataApiOptions({
            url: 'add-edit-relation',
            method: 'post',
            data: fd,
            callback: res => {
                closeAddRelation(e);
                updateRelation(res);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    useEffect(() => {
        setUpdateProf(1);
    }, [authUser]);

    //when update profile === 1 we get the profile client data
    useEffect(() => {
        if (updateProf === 1) {
            setGeneralDataApiOptions({
                url: litigationId ? 'get-matter-data' : 'get-client-data',
                method: 'post',
                data: {
                    user_id: props.user_id || '',
                    id: litigationId || '',
                    type: litigationId ? 'litigations' : '',
                    client_id: props.user_id || '',
                    client_type_id: clientType,
                },
                callback: res => {
                    updateRelation(res);
                    setUpdateProf(0);
                },
                catch: err => {
                    setUpdateProf(0);
                }
            })
        }

    }, [clientType, updateProf]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTimeout(() => {
            setGeneralDataApiOptions({
                url: litigationId ? 'get-matter-data' : 'get-client-data',
                method: 'post',
                data: {
                    user_id: props.user_id || '',
                    id: litigationId || '',
                    type: litigationId ? 'litigations' : '',
                    client_id: props.user_id || '',
                    client_type_id: clientType,
                },
                callback: res => {
                    updateRelation(res);
                    setUpdateProf(0);
                },
                catch: err => {
                    setUpdateProf(0);
                }
            })

        }, 500)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateRelation = (res) => {
        let clientsIds = [];
        let tasksIds = [];
        res?.data?.clients?.map(client => { clientsIds?.push(client?.client_id); return null });
        res?.data?.opponents?.map(opponent => { clientsIds?.push(opponent?.client_id); return null });
        res?.data?.parties?.map(party => { clientsIds?.push(party?.party_id); return null });
        res?.data?.related_tasks?.map(task => { tasksIds?.push(task?.id); return null });
        setData({
            ...res.data,
            relations: res?.data?.relations?.filter(relation => (
                !clientsIds?.includes(relation?.relation?.id || relation?.client?.id) &&
                (!tasksIds?.includes(relation?.task_id || relation?.linked_task_id))

            ))
        });
    }

    return (
        <>
            {
                <>
                    <div className="container-fluid documents-div pt-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col d-flex flex-row">
                                        <div className="col-auto my-auto d-flex align-items-center">
                                            <h1 className="m-0 active">{generalData?.website_titles['relations']}</h1>
                                            {
                                                authUser?.user_role_id <= 2 ?
                                                    <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={(e) => setRelationPopup(true)} alt="add" />
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div onClick={() => { setUpdateProf(1); setClientType(2) }} className="col-auto pe-1 pb-3">
                                <div className={'costs-box-styling px-3 d-flex ' + (clientType === 2 ? ' bg-dark-gray' : '')}>
                                    <IndividualIcon height={20} width={20} />
                                    <p className="ps-2 profile-general-input-title fw-600">{clientTypes()[1].label}</p>
                                </div>
                            </div>
                            <div onClick={() => { setUpdateProf(1); setClientType(1) }} className="col-auto pe-1 pb-3">
                                <div className={'costs-box-styling px-3 d-flex ' + (clientType === 1 ? ' bg-dark-gray' : '')}>
                                    <CompanyIcon height={20} width={20} />
                                    <p className="ps-2 profile-general-input-title fw-600">{clientTypes()[0].label}</p>
                                </div>
                            </div>
                            <div onClick={() => { setUpdateProf(1); setClientType(3) }} className="col-auto pe-1">
                                <div className={'costs-box-styling px-3 d-flex ' + (clientType === 3 ? ' bg-dark-gray' : '')}>
                                    <NgoIcon height={20} width={20} />
                                    <p className="ps-2 profile-general-input-title fw-600">{clientTypes()[2].label}</p>
                                </div>
                            </div>
                            <div onClick={() => { setUpdateProf(1); setClientType(4) }} className="col-auto pe-1">
                                <div className={'costs-box-styling px-3 d-flex ' + (clientType === 4 ? ' bg-dark-gray' : '')}>
                                    <MattersIcon className="active" />
                                    <p className="ps-2 profile-general-input-title fw-600">{generalData?.fixed_titles['matters']}</p>
                                </div>
                            </div>
                            <div onClick={() => { setUpdateProf(1); setClientType(5) }} className="col-auto pe-1">
                                <div className={'costs-box-styling px-3 d-flex ' + (clientType === 5 ? ' bg-dark-gray' : '')}>
                                    <TasksIcon className="active" />
                                    <p className="ps-2 profile-general-input-title fw-600">{generalData?.fixed_titles['tasks']}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-2">
                            {
                                data?.relations?.length > 0 ?
                                    data?.relations.map((singleRelation, relationIndex) => (
                                        <div key={relationIndex} className="col-12 col-md-6 py-2 h-auto">
                                            <div className="cms-table-content position-relative h-100">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="gray-background">

                                                            {
                                                                Number(clientType) === 5 ?
                                                                    <>
                                                                        <div className="row py-2 px-3 cursor-pointer"
                                                                            onClick={() => {
                                                                                navigate(`/tasks/${singleRelation?.task_id || singleRelation?.linked_task_id}/general-info`)
                                                                            }}
                                                                        >
                                                                            <div className="col-auto">
                                                                                {
                                                                                    <img
                                                                                        className="image-user-chat"
                                                                                        src={'/assets/images/task-profile.png'}
                                                                                        alt="chat user"
                                                                                    />

                                                                                }
                                                                            </div>
                                                                            <div className="col ps-0">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-auto">
                                                                                        {

                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.task?.name || singleRelation?.linked_task?.name}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{generalData?.fixed_titles['tasks']}</h5>
                                                                                            </>

                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !singleRelation?.client_board_members_id && !singleRelation?.administrative_body_id ?
                                                                                    <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                        <div className="text-end p-0 position-relative" >
                                                                                            <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleRelation?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleRelation) }} />
                                                                                            <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleRelation?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                                {
                                                                                                    data?.id === singleRelation?.litigation_id ?
                                                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    Number(clientType) === 4
                                                                        ?
                                                                        <div className="row py-2 px-3 cursor-pointer"
                                                                            onClick={() => {
                                                                                navigate(`/matters/litigations/${Number(singleRelation?.litigation_id) === Number(litigationId) ? singleRelation?.linked_litigation_id : singleRelation?.litigation_id}/general-info`)
                                                                            }}
                                                                        >
                                                                            <div className="col-auto">
                                                                                {
                                                                                    <img
                                                                                        className="image-user-chat"
                                                                                        src={'/assets/images/litigation-profile.png'}
                                                                                        alt="chat user"
                                                                                    />

                                                                                }
                                                                            </div>
                                                                            <div className="col ps-0">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-auto">
                                                                                        {
                                                                                            Number(singleRelation?.litigation_id) === Number(litigationId) ?

                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">{singleRelation?.linked_litigation?.title}</p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">{singleRelation?.litigation?.title}</p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                                </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !singleRelation?.client_board_members_id && !singleRelation?.administrative_body_id ?
                                                                                    <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                        <div className="text-end p-0 position-relative" >
                                                                                            <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleRelation?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleRelation) }} />
                                                                                            <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleRelation?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                                {
                                                                                                    (data?.id === singleRelation?.client_id || Number(litigationId) === Number(singleRelation?.litigation_id)) &&
                                                                                                    <>
                                                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                    </>

                                                                                                }
                                                                                                <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className="row py-2 px-3 cursor-pointer"
                                                                            onClick={() => {
                                                                                navigate(`/${singleRelation?.user_id ? 'profile' : 'client'}/${Number(singleRelation?.litigation_id) === Number(litigationId) ? singleRelation?.relation_id || singleRelation?.user_id : singleRelation?.client_id}/general-info`)
                                                                            }}
                                                                        >
                                                                            <div className="col-auto">
                                                                                {
                                                                                    singleRelation?.client_id === Number(props.user_id) ?

                                                                                        <img
                                                                                            className="image-user-chat"
                                                                                            src={
                                                                                                singleRelation?.user?.picture ?
                                                                                                    singleRelation?.user?.full_path_picture :
                                                                                                    (singleRelation?.relation?.picture ?
                                                                                                        singleRelation?.relation?.full_path_picture :
                                                                                                        (singleRelation?.relation?.client_type_id === 1 ?
                                                                                                            generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                            (singleRelation?.relation?.client_type_id === 2 ?
                                                                                                                generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                (Number(singleRelation?.relation?.client_type_id) === 3) ?

                                                                                                                    generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                    :
                                                                                                                    generalData?.fixed_settings?.full_path_no_individual_picture
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                            }
                                                                                            alt="chat user"
                                                                                        />
                                                                                        :
                                                                                        litigationId
                                                                                            ?
                                                                                            <img
                                                                                                className="image-user-chat"
                                                                                                src={
                                                                                                    singleRelation?.user ? (
                                                                                                        singleRelation?.user?.picture ?
                                                                                                            singleRelation?.user?.full_path_picture :
                                                                                                            generalData?.fixed_settings?.full_path_no_profile_picture
                                                                                                    )
                                                                                                        :

                                                                                                        (singleRelation?.client?.picture ?
                                                                                                            singleRelation?.client?.full_path_picture :
                                                                                                            (singleRelation?.relation?.picture ?
                                                                                                                singleRelation?.relation?.full_path_picture
                                                                                                                :
                                                                                                                (singleRelation?.client ?

                                                                                                                    (singleRelation?.client?.client_type_id === 1 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                        (singleRelation?.client?.client_type_id === 2 ?
                                                                                                                            generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                            generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                        )
                                                                                                                    )
                                                                                                                    :
                                                                                                                    (singleRelation?.relation?.client_type_id === 1 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                        (singleRelation?.relation?.client_type_id === 2 ?
                                                                                                                            generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                            generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                        )
                                                                                                                    )
                                                                                                                )
                                                                                                            )


                                                                                                        )
                                                                                                }
                                                                                                alt="chat user"
                                                                                            />
                                                                                            :
                                                                                            <img
                                                                                                className="image-user-chat"
                                                                                                src={
                                                                                                    singleRelation?.user?.picture ?
                                                                                                        singleRelation?.user?.full_path_picture :
                                                                                                        (singleRelation?.client?.picture ?
                                                                                                            singleRelation?.client?.full_path_picture :
                                                                                                            (singleRelation?.client?.client_type_id === 1 ?
                                                                                                                generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                (singleRelation?.client?.client_type_id === 2 || singleRelation?.user ?
                                                                                                                    generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                    generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                }
                                                                                                alt="chat user"
                                                                                            />
                                                                                }
                                                                            </div>
                                                                            <div className="col ps-0">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-auto">
                                                                                        {
                                                                                            singleRelation?.client_id === Number(props.user_id) ?
                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.relation?.name}</p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.relation?.client_type?.title}</h5>
                                                                                                </>
                                                                                                :
                                                                                                litigationId ?
                                                                                                    <>
                                                                                                        <p className="m-0 font-semi-bold">
                                                                                                            {
                                                                                                                singleRelation?.user?.name ?
                                                                                                                    singleRelation?.user?.name :
                                                                                                                    singleRelation?.client?.name ?
                                                                                                                        singleRelation?.client?.name :
                                                                                                                        singleRelation?.relation?.name
                                                                                                            }
                                                                                                        </p>
                                                                                                        <h5 className="locked-inputs w-100 fs-14">
                                                                                                            {
                                                                                                                singleRelation?.user ?
                                                                                                                    generalData.website_titles['user'] :
                                                                                                                    singleRelation?.client ?
                                                                                                                        singleRelation?.client?.client_type?.title
                                                                                                                        : singleRelation?.relation?.client_type?.title
                                                                                                            }
                                                                                                        </h5>
                                                                                                    </>
                                                                                                    :

                                                                                                    <>
                                                                                                        <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.client?.name}</p>
                                                                                                        <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.client?.client_type?.title}</h5>
                                                                                                    </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !singleRelation?.client_board_members_id && !singleRelation?.administrative_body_id ?
                                                                                    <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                        <div className="text-end p-0 position-relative" >
                                                                                            <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleRelation?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleRelation) }} />
                                                                                            <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleRelation?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                                {
                                                                                                    data?.id === singleRelation?.client_id || Number(litigationId) === Number(singleRelation?.litigation_id) ?
                                                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                {

                                                    singleRelation?.client_id === Number(props.user_id)
                                                        ?
                                                        <>
                                                            {
                                                                singleRelation?.user?.id
                                                                    ?
                                                                    <RelationCard description={singleRelation.description} user={1} userData={singleRelation?.user} />
                                                                    :
                                                                    <>
                                                                        {
                                                                            singleRelation?.linked_litigation?.id
                                                                                ?
                                                                                <RelationCard description={singleRelation.description} relationTo={1} litigationStage={singleRelation?.linked_litigation?.stages[0]?.stage?.title} litigationNumber={singleRelation?.linked_litigation?.number} userData={singleRelation?.linked_litigation} />
                                                                                :
                                                                                <RelationCard description={singleRelation.description} relationTo={1} userData={singleRelation?.relation} />
                                                                        }
                                                                    </>
                                                            }
                                                        </>

                                                        :
                                                        litigationId ?
                                                            singleRelation?.client ?
                                                                <RelationCard description={singleRelation?.description} client={1} userData={singleRelation?.client} />
                                                                :
                                                                singleRelation?.user ?

                                                                    <RelationCard description={singleRelation?.description} user={1} userData={singleRelation?.user} />
                                                                    :
                                                                    <RelationCard description={singleRelation?.description} litigationStage={singleRelation?.linked_litigation?.stages[0]?.stage?.title} litigationNumber={singleRelation?.linked_litigation?.number} client={1} userData={singleRelation?.relation} />
                                                            :

                                                            <>
                                                                {

                                                                    Number(props.user_id) === singleRelation?.relation_id
                                                                        ?
                                                                        <RelationCard description={singleRelation.description} client={1} userData={singleRelation?.client} />
                                                                        :
                                                                        <>
                                                                            {
                                                                                Number(singleRelation.litigation_id) === Number(litigationId) &&
                                                                                <RelationCard description={singleRelation.description} litigationStage={singleRelation?.linked_litigation?.stages[0]?.stage?.title} litigationNumber={singleRelation?.litigation?.number} litigation={1} userData={singleRelation?.litigation} />
                                                                            }
                                                                        </>
                                                                }

                                                            </>

                                                }
                                            </div>
                                        </div>
                                    ))
                                    :
                                    (
                                        (
                                            data?.clients?.filter(client => (Number(client?.client?.client_type_id) === Number(clientType)))?.length === 0
                                            && data?.opponents?.filter(opp => (Number(opp?.client?.client_type_id) === Number(clientType))).length === 0
                                            && data?.parties?.filter(party => (Number(party?.party?.client_type_id) === Number(clientType))).length === 0
                                        ) &&
                                        <div className="col-12">
                                            <h5 className="locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-relations']}</h5>
                                        </div>
                                    )

                            }


                            {
                                data?.clients?.length > 0 &&
                                data?.clients?.map((singleRelation, relationIndex) => (
                                    Number(singleRelation?.client?.client_type_id) === Number(clientType) &&
                                    <div key={relationIndex} className="col-12 col-md-6 py-2 h-auto">
                                        <div className="cms-table-content position-relative h-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="gray-background">
                                                        {
                                                            Number(clientType) === 4
                                                                ?
                                                                <>
                                                                    <div className="row py-2 px-3">
                                                                        <div className="col-auto">
                                                                            {
                                                                                <img
                                                                                    className="image-user-chat"
                                                                                    src={'/assets/images/litigation-profile.png'}
                                                                                    alt="chat user"
                                                                                />

                                                                            }
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            <div className="row justify-content-between">
                                                                                <div className="col-auto">
                                                                                    {
                                                                                        Number(singleRelation?.litigation_id) === Number(litigationId) ?

                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.linked_litigation?.title}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.litigation?.title}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            !singleRelation?.client_board_members_id && !singleRelation?.administrative_body_id ?
                                                                                <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                    <div className="text-end p-0 position-relative" >
                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleRelation?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleRelation) }} />
                                                                                        <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleRelation?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                            {
                                                                                                (data?.id === singleRelation?.client_id || Number(litigationId) === Number(singleRelation?.litigation_id)) &&
                                                                                                <>
                                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                </>

                                                                                            }
                                                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </>

                                                                :
                                                                <>
                                                                    <div
                                                                        className="row py-2 px-3"
                                                                        onClick={() => {
                                                                            navigate(`/client/${singleRelation?.client_id}/general-info`)
                                                                        }}
                                                                    >
                                                                        <div className="col-auto">
                                                                            {
                                                                                singleRelation?.client_id === Number(props.user_id) ?
                                                                                    <img
                                                                                        className="image-user-chat"
                                                                                        src={
                                                                                            singleRelation?.user?.picture ?
                                                                                                singleRelation?.user?.full_path_picture :
                                                                                                (singleRelation?.client?.picture ?
                                                                                                    singleRelation?.client?.full_path_picture :
                                                                                                    (singleRelation?.client?.client_type_id === 1 ?
                                                                                                        generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                        (singleRelation?.client?.client_type_id === 2 ?
                                                                                                            generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                            (Number(singleRelation?.client?.client_type_id) === 3) ?

                                                                                                                generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                :
                                                                                                                generalData?.fixed_settings?.full_path_no_individual_picture
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                        }
                                                                                        alt="chat user"
                                                                                    />
                                                                                    :
                                                                                    litigationId
                                                                                        ?
                                                                                        <img
                                                                                            className="image-user-chat"
                                                                                            src={
                                                                                                singleRelation?.user?.picture ?
                                                                                                    singleRelation?.user?.full_path_picture :
                                                                                                    (singleRelation?.client?.picture ?
                                                                                                        singleRelation?.client?.full_path_picture :
                                                                                                        (singleRelation?.client?.picture ?
                                                                                                            singleRelation?.client?.full_path_picture
                                                                                                            :
                                                                                                            (singleRelation?.client ?

                                                                                                                (singleRelation?.client?.client_type_id === 1 ?
                                                                                                                    generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                    (singleRelation?.client?.client_type_id === 2 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                        generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                    )
                                                                                                                )
                                                                                                                :
                                                                                                                (singleRelation?.client?.client_type_id === 1 ?
                                                                                                                    generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                    (singleRelation?.client?.client_type_id === 2 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                        generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        )


                                                                                                    )
                                                                                            }
                                                                                            alt="chat user"
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            className="image-user-chat"
                                                                                            src={
                                                                                                singleRelation?.user?.picture ?
                                                                                                    singleRelation?.user?.full_path_picture :
                                                                                                    (singleRelation?.client?.picture ?
                                                                                                        singleRelation?.client?.full_path_picture :
                                                                                                        (singleRelation?.client?.client_type_id === 1 ?
                                                                                                            generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                            (singleRelation?.client?.client_type_id === 2 ?
                                                                                                                generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                            }
                                                                                            alt="chat user"
                                                                                        />
                                                                            }
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            <div className="row justify-content-between">
                                                                                <div className="col-auto">
                                                                                    {
                                                                                        singleRelation?.client_id === Number(props.user_id) ?
                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.client?.name}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.client?.client_type?.title}</h5>
                                                                                            </>
                                                                                            :
                                                                                            litigationId ?
                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">
                                                                                                        {
                                                                                                            singleRelation?.user?.name ?
                                                                                                                singleRelation?.user?.name :
                                                                                                                singleRelation?.client?.name ?
                                                                                                                    singleRelation?.client?.name :
                                                                                                                    singleRelation?.client?.name
                                                                                                        }
                                                                                                    </p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                                                        {
                                                                                                            singleRelation?.user ?
                                                                                                                generalData.website_titles['user'] :
                                                                                                                singleRelation?.client ?
                                                                                                                    singleRelation?.client?.client_type?.title
                                                                                                                    : singleRelation?.client?.client_type?.title
                                                                                                        }
                                                                                                    </h5>
                                                                                                </>
                                                                                                :

                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.client?.name}</p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.client?.client_type?.title}</h5>
                                                                                                </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            {

                                                singleRelation?.client_id === Number(props.user_id)
                                                    ?
                                                    <>
                                                        {
                                                            singleRelation?.user?.id
                                                                ?
                                                                <RelationCard description={singleRelation.description} user={1} userData={singleRelation?.user} />
                                                                :
                                                                <>
                                                                    {
                                                                        singleRelation?.linked_litigation?.id
                                                                            ?
                                                                            <RelationCard description={singleRelation.description} relationTo={1} userData={singleRelation?.linked_litigation} />
                                                                            :
                                                                            <RelationCard description={singleRelation.description} relationTo={1} userData={singleRelation?.relation} />
                                                                    }
                                                                </>
                                                        }
                                                    </>

                                                    :
                                                    litigationId ?
                                                        singleRelation?.client ?
                                                            <RelationCard description={singleRelation?.description} client={1} userData={singleRelation?.client} />
                                                            :
                                                            <RelationCard description={singleRelation?.description} client={1} userData={singleRelation?.relation} />
                                                        :

                                                        <>
                                                            {

                                                                Number(props.user_id) === singleRelation?.relation_id
                                                                    ?
                                                                    <RelationCard description={singleRelation.description} client={1} userData={singleRelation?.client} />
                                                                    :
                                                                    <>
                                                                        {
                                                                            Number(singleRelation.litigation_id) === Number(litigationId) &&
                                                                            <RelationCard description={singleRelation.description} litigation={1} userData={singleRelation?.litigation} />
                                                                        }
                                                                    </>
                                                            }

                                                        </>

                                            }
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                data?.opponents?.length > 0 &&
                                data?.opponents?.map((singleRelation, relationIndex) => (
                                    Number(singleRelation?.client?.client_type_id) === Number(clientType) &&
                                    <div key={relationIndex} className="col-12 col-md-6 py-2 h-auto">
                                        <div className="cms-table-content position-relative h-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="gray-background">
                                                        {
                                                            Number(clientType) === 4
                                                                ?
                                                                <>
                                                                    <div className="row py-2 px-3">
                                                                        <div className="col-auto">
                                                                            {
                                                                                <img
                                                                                    className="image-user-chat"
                                                                                    src={'/assets/images/litigation-profile.png'}
                                                                                    alt="chat user"
                                                                                />

                                                                            }
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            <div className="row justify-content-between">
                                                                                <div className="col-auto">
                                                                                    {
                                                                                        Number(singleRelation?.litigation_id) === Number(litigationId) ?

                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.linked_litigation?.title}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.litigation?.title}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            !singleRelation?.client_board_members_id && !singleRelation?.administrative_body_id ?
                                                                                <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                    <div className="text-end p-0 position-relative" >
                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleRelation?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleRelation) }} />
                                                                                        <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleRelation?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                            {
                                                                                                (data?.id === singleRelation?.client_id || Number(litigationId) === Number(singleRelation?.litigation_id)) &&
                                                                                                <>
                                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                </>

                                                                                            }
                                                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div
                                                                        className="row py-2 px-3"
                                                                        onClick={() => {
                                                                            navigate(`/client/${singleRelation?.client_id}/general-info`)
                                                                        }}
                                                                    >
                                                                        <div className="col-auto">
                                                                            {
                                                                                singleRelation?.client_id === Number(props.user_id) ?
                                                                                    <img
                                                                                        className="image-user-chat"
                                                                                        src={
                                                                                            singleRelation?.user?.picture ?
                                                                                                singleRelation?.user?.full_path_picture :
                                                                                                (singleRelation?.client?.picture ?
                                                                                                    singleRelation?.client?.full_path_picture :
                                                                                                    (singleRelation?.client?.client_type_id === 1 ?
                                                                                                        generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                        (singleRelation?.client?.client_type_id === 2 ?
                                                                                                            generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                            (Number(singleRelation?.client?.client_type_id) === 3) ?

                                                                                                                generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                :
                                                                                                                generalData?.fixed_settings?.full_path_no_individual_picture
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                        }
                                                                                        alt="chat user"
                                                                                    />
                                                                                    :
                                                                                    litigationId
                                                                                        ?
                                                                                        <img
                                                                                            className="image-user-chat"
                                                                                            src={
                                                                                                singleRelation?.user?.picture ?
                                                                                                    singleRelation?.user?.full_path_picture :
                                                                                                    (singleRelation?.client?.picture ?
                                                                                                        singleRelation?.client?.full_path_picture :
                                                                                                        (singleRelation?.client?.picture ?
                                                                                                            singleRelation?.client?.full_path_picture
                                                                                                            :
                                                                                                            (singleRelation?.client ?

                                                                                                                (singleRelation?.client?.client_type_id === 1 ?
                                                                                                                    generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                    (singleRelation?.client?.client_type_id === 2 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                        generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                    )
                                                                                                                )
                                                                                                                :
                                                                                                                (singleRelation?.client?.client_type_id === 1 ?
                                                                                                                    generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                    (singleRelation?.client?.client_type_id === 2 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                        generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        )


                                                                                                    )
                                                                                            }
                                                                                            alt="chat user"
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            className="image-user-chat"
                                                                                            src={
                                                                                                singleRelation?.user?.picture ?
                                                                                                    singleRelation?.user?.full_path_picture :
                                                                                                    (singleRelation?.client?.picture ?
                                                                                                        singleRelation?.client?.full_path_picture :
                                                                                                        (singleRelation?.client?.client_type_id === 1 ?
                                                                                                            generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                            (singleRelation?.client?.client_type_id === 2 ?
                                                                                                                generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                            }
                                                                                            alt="chat user"
                                                                                        />
                                                                            }
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            <div className="row justify-content-between">
                                                                                <div className="col-auto">
                                                                                    {
                                                                                        singleRelation?.client_id === Number(props.user_id) ?
                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.client?.name}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.client?.client_type?.title}</h5>
                                                                                            </>
                                                                                            :
                                                                                            litigationId ?
                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">
                                                                                                        {
                                                                                                            singleRelation?.user?.name ?
                                                                                                                singleRelation?.user?.name :
                                                                                                                singleRelation?.client?.name ?
                                                                                                                    singleRelation?.client?.name :
                                                                                                                    singleRelation?.client?.name
                                                                                                        }
                                                                                                    </p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                                                        {
                                                                                                            singleRelation?.user ?
                                                                                                                generalData.website_titles['user'] :
                                                                                                                singleRelation?.client ?
                                                                                                                    singleRelation?.client?.client_type?.title
                                                                                                                    : singleRelation?.client?.client_type?.title
                                                                                                        }
                                                                                                    </h5>
                                                                                                </>
                                                                                                :

                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.client?.name}</p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.client?.client_type?.title}</h5>
                                                                                                </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            {

                                                singleRelation?.client_id === Number(props.user_id)
                                                    ?
                                                    <>
                                                        {
                                                            singleRelation?.user?.id
                                                                ?
                                                                <RelationCard description={singleRelation.description} user={1} userData={singleRelation?.user} />
                                                                :
                                                                <>
                                                                    {
                                                                        singleRelation?.linked_litigation?.id
                                                                            ?
                                                                            <RelationCard description={singleRelation.description} relationTo={1} userData={singleRelation?.linked_litigation} />
                                                                            :
                                                                            <RelationCard description={singleRelation.description} relationTo={1} userData={singleRelation?.relation} />
                                                                    }
                                                                </>
                                                        }
                                                    </>

                                                    :
                                                    litigationId ?
                                                        singleRelation?.client ?
                                                            <RelationCard description={singleRelation?.description} client={1} userData={singleRelation?.client} />
                                                            :
                                                            <RelationCard description={singleRelation?.description} client={1} userData={singleRelation?.relation} />
                                                        :

                                                        <>
                                                            {

                                                                Number(props.user_id) === singleRelation?.relation_id
                                                                    ?
                                                                    <RelationCard description={singleRelation.description} client={1} userData={singleRelation?.client} />
                                                                    :
                                                                    <>
                                                                        {
                                                                            Number(singleRelation.litigation_id) === Number(litigationId) &&
                                                                            <RelationCard description={singleRelation.description} litigation={1} userData={singleRelation?.litigation} />
                                                                        }
                                                                    </>
                                                            }

                                                        </>

                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                data?.parties?.length > 0 &&
                                data?.parties?.map((singleRelation, relationIndex) => (
                                    Number(singleRelation?.party?.client_type_id) === Number(clientType) &&
                                    <div key={relationIndex} className="col-12 col-md-6 py-2 h-auto">
                                        <div className="cms-table-content position-relative h-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="gray-background">
                                                        {
                                                            Number(clientType) === 4
                                                                ?
                                                                <>
                                                                    <div className="row py-2 px-3">
                                                                        <div className="col-auto">
                                                                            {
                                                                                <img
                                                                                    className="image-user-chat"
                                                                                    src={'/assets/images/litigation-profile.png'}
                                                                                    alt="chat user"
                                                                                />

                                                                            }
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            <div className="row justify-content-between">
                                                                                <div className="col-auto">
                                                                                    {
                                                                                        Number(singleRelation?.litigation_id) === Number(litigationId) ?

                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.linked_litigation?.title}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.litigation?.title}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{generalData?.matterTypes[0]?.title}</h5>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            !singleRelation?.client_board_members_id && !singleRelation?.administrative_body_id ?
                                                                                <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                    <div className="text-end p-0 position-relative" >
                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleRelation?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleRelation) }} />
                                                                                        <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleRelation?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                            {
                                                                                                (data?.id === singleRelation?.party_id || Number(litigationId) === Number(singleRelation?.litigation_id)) &&
                                                                                                <>
                                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                </>

                                                                                            }
                                                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleRelation) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div
                                                                        className="row py-2 px-3"
                                                                        onClick={() => {
                                                                            navigate(`/client/${singleRelation?.party_id}/general-info`)
                                                                        }}
                                                                    >
                                                                        <div className="col-auto">
                                                                            {
                                                                                singleRelation?.party_id === Number(props.user_id) ?
                                                                                    <img
                                                                                        className="image-user-chat"
                                                                                        src={
                                                                                            singleRelation?.user?.picture ?
                                                                                                singleRelation?.user?.full_path_picture :
                                                                                                (singleRelation?.party?.picture ?
                                                                                                    singleRelation?.party?.full_path_picture :
                                                                                                    (singleRelation?.party?.client_type_id === 1 ?
                                                                                                        generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                        (singleRelation?.party?.client_type_id === 2 ?
                                                                                                            generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                            (Number(singleRelation?.party?.client_type_id) === 3) ?

                                                                                                                generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                :
                                                                                                                generalData?.fixed_settings?.full_path_no_individual_picture
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                        }
                                                                                        alt="chat user"
                                                                                    />
                                                                                    :
                                                                                    litigationId
                                                                                        ?
                                                                                        <img
                                                                                            className="image-user-chat"
                                                                                            src={
                                                                                                singleRelation?.user?.picture ?
                                                                                                    singleRelation?.user?.full_path_picture :
                                                                                                    (singleRelation?.party?.picture ?
                                                                                                        singleRelation?.party?.full_path_picture :
                                                                                                        (singleRelation?.party?.picture ?
                                                                                                            singleRelation?.party?.full_path_picture
                                                                                                            :
                                                                                                            (singleRelation?.party ?

                                                                                                                (singleRelation?.party?.client_type_id === 1 ?
                                                                                                                    generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                    (singleRelation?.party?.client_type_id === 2 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                        generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                    )
                                                                                                                )
                                                                                                                :
                                                                                                                (singleRelation?.party?.client_type_id === 1 ?
                                                                                                                    generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                                    (singleRelation?.party?.client_type_id === 2 ?
                                                                                                                        generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                        generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        )


                                                                                                    )
                                                                                            }
                                                                                            alt="chat user"
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            className="image-user-chat"
                                                                                            src={
                                                                                                singleRelation?.user?.picture ?
                                                                                                    singleRelation?.user?.full_path_picture :
                                                                                                    (singleRelation?.party?.picture ?
                                                                                                        singleRelation?.party?.full_path_picture :
                                                                                                        (singleRelation?.party?.client_type_id === 1 ?
                                                                                                            generalData?.fixed_settings?.full_path_no_company_picture :
                                                                                                            (singleRelation?.party?.client_type_id === 2 ?
                                                                                                                generalData?.fixed_settings?.full_path_no_individual_picture :
                                                                                                                generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                            }
                                                                                            alt="chat user"
                                                                                        />
                                                                            }
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            <div className="row justify-content-between">
                                                                                <div className="col-auto">
                                                                                    {
                                                                                        singleRelation?.client_id === Number(props.user_id) ?
                                                                                            <>
                                                                                                <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.party?.name}</p>
                                                                                                <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.party?.client_type?.title}</h5>
                                                                                            </>
                                                                                            :
                                                                                            litigationId ?
                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">
                                                                                                        {
                                                                                                            singleRelation?.user?.name ?
                                                                                                                singleRelation?.user?.name :
                                                                                                                singleRelation?.party?.name ?
                                                                                                                    singleRelation?.party?.name :
                                                                                                                    singleRelation?.party?.name
                                                                                                        }
                                                                                                    </p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                                                        {
                                                                                                            singleRelation?.user ?
                                                                                                                generalData.website_titles['user'] :
                                                                                                                singleRelation?.party ?
                                                                                                                    singleRelation?.party?.client_type?.title
                                                                                                                    : singleRelation?.party?.client_type?.title
                                                                                                        }
                                                                                                    </h5>
                                                                                                </>
                                                                                                :

                                                                                                <>
                                                                                                    <p className="m-0 font-semi-bold">{singleRelation?.user?.name ? singleRelation?.user?.name : singleRelation?.party?.name}</p>
                                                                                                    <h5 className="locked-inputs w-100 fs-14">{singleRelation?.user ? generalData.website_titles['user'] : singleRelation?.party?.client_type?.title}</h5>
                                                                                                </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            {

                                                singleRelation?.client_id === Number(props.user_id)
                                                    ?
                                                    <>
                                                        {
                                                            singleRelation?.user?.id
                                                                ?
                                                                <RelationCard description={singleRelation.description} user={1} userData={singleRelation?.user} />
                                                                :
                                                                <>
                                                                    {
                                                                        singleRelation?.linked_litigation?.id
                                                                            ?
                                                                            <RelationCard description={singleRelation.description} relationTo={1} userData={singleRelation?.linked_litigation} />
                                                                            :
                                                                            <RelationCard description={singleRelation.description} relationTo={1} userData={singleRelation?.relation} />
                                                                    }
                                                                </>
                                                        }
                                                    </>

                                                    :
                                                    litigationId ?
                                                        singleRelation?.client ?
                                                            <RelationCard description={singleRelation?.description} client={1} userData={singleRelation?.client} />
                                                            :
                                                            <RelationCard description={singleRelation?.description} client={1} userData={singleRelation?.relation} />
                                                        :

                                                        <>
                                                            {

                                                                Number(props.user_id) === singleRelation?.relation_id
                                                                    ?
                                                                    <RelationCard description={singleRelation.description} client={1} userData={singleRelation?.client} />
                                                                    :
                                                                    <>
                                                                        {
                                                                            Number(singleRelation.litigation_id) === Number(litigationId) &&
                                                                            <RelationCard description={singleRelation.description} litigation={1} userData={singleRelation?.litigation} />
                                                                        }
                                                                    </>
                                                            }

                                                        </>

                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                Number(clientType) === 5 && data?.related_tasks?.length > 0 &&
                                data?.related_tasks?.map((singleRelation, relationIndex) => (
                                    <div key={relationIndex} className="col-12 col-md-6 py-2 h-auto">
                                        <div className="cms-table-content position-relative h-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="gray-background">
                                                        <>
                                                            <div className="row py-2 px-3 cursor-pointer"
                                                                onClick={() => {
                                                                    navigate(`/tasks/${singleRelation?.id}/general-info`)
                                                                }}
                                                            >
                                                                <div className="col-auto">
                                                                    {
                                                                        <img
                                                                            className="image-user-chat"
                                                                            src={'/assets/images/task-profile.png'}
                                                                            alt="chat user"
                                                                        />

                                                                    }
                                                                </div>
                                                                <div className="col ps-0">
                                                                    <div className="row justify-content-between">
                                                                        <div className="col-auto">
                                                                            {

                                                                                <>
                                                                                    <p className="m-0 font-semi-bold">{singleRelation?.name}</p>
                                                                                    <h5 className="locked-inputs w-100 fs-14">{generalData?.fixed_titles['tasks']}</h5>
                                                                                </>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>

                                                    </div>
                                                </div>
                                            </div>

                                            <RelationCard description={singleRelation.note} litigation={1} userData={singleRelation} />


                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {
                        //relation popup
                        relationPopup ?
                            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                <div className="pop-up-api-box">
                                    <CloseIcon className="cursor-pointer" onClick={(e) => closeAddRelation(e)} />
                                    <div className="text-center ">
                                        <div className="containter-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentRelation?.id ? generalData?.website_titles['edit-relation-title'] : generalData?.website_titles['add-relation-title']}</h1>
                                                </div>
                                                <form onSubmit={submitRelation}>
                                                    <div className="col-12 pt-2 pb-4">
                                                        <div className="row">
                                                            <div className="col-12 col-md-8 pt-2">
                                                                <div className="postion-relative text-start justify-content-start w-100">
                                                                    <RelationsSelect
                                                                        withUsers={1}
                                                                        selectedRelation={selectedRelation}
                                                                        setSelectedRelation={setSelectedRelation}
                                                                        profile={data}
                                                                        defaultSelected={clientType ? (Number(clientType) === 5 ? 'tasks' : (Number(clientType) === 4 ? 'matters' : (Number(clientType) === 1 ? 'company' : (clientType === 2 ? 'individual' : 'ngo')))) : 'individual'}
                                                                    />
                                                                </div>
                                                                {
                                                                    errorsReturned?.relation_name ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.relation_name}</h5>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    errorsReturned?.relation_type ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.relation_type}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-12 pt-2">
                                                                <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.website_titles['description']} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                                {
                                                                    errorsReturned?.description ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.description}</h5>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    errorsReturned?.relation_exists ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.relation_exists}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <button type="submit" className="popup-button my-auto">{currentRelation?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        //delete popup
                        deletePopup ?
                            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                <div className="pop-up-api-box">
                                    <CloseIcon className="cursor-pointer" onClick={(e) => cancelDeletePopup(e)} />
                                    <div className="text-center ">
                                        <div className="containter-fluid">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                    <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                    <button onClick={(e) => deleteElement(e)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                    <button onClick={(e) => cancelDeletePopup(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                    <Api options={generalDataApiOptions} />
                </>
            }
        </>
    )
}