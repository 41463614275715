import React, { useEffect, useRef, useState } from 'react'
import useOutsideClick from '../useOutsideClick';
import { FlagIcon } from './Icons';
import Api from './Api';

export default function PriorityComponent({ returnSingleTask, closeInput, setCloseInput, searchPage, currentPriority, onChange = () => { }, priorities, selectedPriority, className, index, targetName, target, api, showFlagOnly, hideShadow, activeTasksType = "", editable = true, showToolTip }) {

    const [activePriority, setActivePriority] = useState();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});


    const handleStatusChange = (priority) => {
        setActivePriority(priority)
        setIsMenuOpen(false)

        // send api call
        let fd = new FormData();
        fd.append((targetName || 'target_id'), target);
        fd.append('status', priority.id)
        fd.append('type', activeTasksType)
        if (returnSingleTask && returnSingleTask === 1) {
            fd.append('return_single_task', 1)
        }
        setGeneralDataApiOptions({
            url: api,
            method: 'post',
            data: fd,
            callback: res => {
                setActivePriority(priority)
                onChange(res);
            },
            catch: err => {
                if (err) {
                    setActivePriority()
                }
            }
        });
    }

    const dropdownRef = useRef();

    useOutsideClick(dropdownRef, () => { setIsMenuOpen(false) })
    useEffect(() => {
        setActivePriority(selectedPriority);
    }, [selectedPriority]);// eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(()=>{
    //     if(currentPriority){
    //         if(currentPriority !== targetName+target){
    //             setIsMenuOpen(false);
    //         }
    //     }
    // },[currentPriority]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchPage === 1) {
            if (closeInput === '') {
                setIsMenuOpen(false);
            }
        }
    }, [closeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={dropdownRef} className={`position-relative cursor-pointer ${className ? className : ''}`}>
            <Api options={generalDataApiOptions} />

            {
                editable ?
                    <div onClick={() => { editable && (searchPage === 1 && setCloseInput(targetName + target)); setIsMenuOpen(!isMenuOpen) }} className={`text-center px-3 py-1 h-100 text-white d-flex align-items-center ${!hideShadow ? 'shadow' : ''} ${showToolTip ? 'onhover-showtooltip' : ''}`}>
                        <FlagIcon className={`me-2`} color={activePriority?.color} />
                        {
                            !showFlagOnly &&
                            <p className='mb-0' style={{ color: activePriority?.color }}>{activePriority?.title}</p>
                        }
                        <p className='tooltip pe-none position-relative' style={{ color: activePriority?.color }}>{activePriority?.title}</p>
                    </div>
                    :
                    <div className={`text-center px-3 py-1 h-100 text-white d-flex align-items-center ${!hideShadow ? 'shadow' : ''} ${showToolTip ? 'onhover-showtooltip' : ''}`}>
                        <FlagIcon className={`me-2`} color={activePriority?.color} />
                        {
                            !showFlagOnly &&
                            <p className='mb-0' style={{ color: activePriority?.color }}>{activePriority?.title}</p>
                        }
                        <p className='tooltip pe-none position-relative' style={{ color: activePriority?.color }}>{activePriority?.title}</p>
                    </div>
            }
            {
                (isMenuOpen && ((searchPage === 1 && (closeInput === targetName + target)) || searchPage !== 1 || !searchPage)) &&
                <div className='status-dropdown-menu mt-2 px-2 position-absolute top-100 start-0 w-100' style={{ zIndex: 99998 }}>
                    {
                        priorities?.length > 0 ?
                            priorities?.map((priority, ind) => (
                                <div key={ind} onClick={() => { handleStatusChange(priority) }} className='cursor-pointer row flex-nowrap gx-2 status-item align-items-center'>
                                    <div className='col-auto'>
                                        <FlagIcon color={priority?.color} />
                                    </div>
                                    <div className='col-auto'>
                                        <p className='m-0'>
                                            {priority?.title}
                                        </p>
                                    </div>
                                </div>
                            )) :
                            <div>No Options</div>
                    }
                </div>
            }

        </div>
    )
}
