import { useContext } from "react";
import GlobalState from "../contexts/GlobalState";
import { useNavigate } from "react-router";

export default function Popup(props) {

    const { authUserGlobal, logoutGlobal } = useContext(GlobalState);
    const [, setAuthUser] = authUserGlobal;
    const [logoutPopup, setLogoutPopup] = logoutGlobal;
    const history = useNavigate();

    function logout() {
        setLogoutPopup(false);
        setAuthUser();
        localStorage.setItem('auth_token', '');
        history('/sign-in');
    }

    return (
        <>
            {
                logoutPopup ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <svg className="cursor-pointer" onClick={() => setLogoutPopup(false)} id="Icon_feather-plus" data-name="Icon feather-plus" xmlns="http://www.w3.org/2000/svg" width="21.213" height="21.213" viewBox="0 0 21.213 21.213">
                                <g id="Icon_feather-plus-2" data-name="Icon feather-plus" transform="translate(10.607) rotate(45)">
                                    <path id="Path_329" data-name="Path 329" d="M18,7.5v15" transform="translate(-10.5 -7.5)" fill="none" stroke="#144676" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                    <path id="Path_330" data-name="Path 330" d="M7.5,18h15" transform="translate(-7.5 -10.5)" fill="none" stroke="#144676" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                </g>
                            </svg>
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        {
                                            props.logout === 1 ?
                                                <>
                                                    <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                        <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{props.title ? props.title : null}</h1>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                        {
                                                            props.logoutTitle ?
                                                                <button onClick={() => logout()} type="button" className="popup-button my-auto mx-2 w-auto px-3">{props.logoutTitle}</button>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            props.logoutTitle ?
                                                                <button onClick={() => setLogoutPopup(false)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{props.cancelTitle}</button>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}