import { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import Select from 'react-select';
import ClientPopup from "../components/ClientPopup";
import GlobalState from "../contexts/GlobalState";
import { useNavigate } from "react-router";
import Api from "../components/Api";
import Pagination from "../components/Pagination";
import { AddIcon, CloseIcon, CompanyIcon, IndividualIcon, NgoIcon, ProfileCard, ThreeDotsMenu } from "../components/Icons";

export default function Contacts() {

    const { apiSuccessDataGlobal, generalDataGlobal, authUserGlobal, addClientGlobal, currentClientGlobal } = useContext(GlobalState);
    const [authUser, setAuthUser] = authUserGlobal;
    const [generalData,] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const ref = useRef(null);
    const [addClient, setAddClient] = addClientGlobal;
    const [currentClient, setCurrentClient] = currentClientGlobal;
    const [openEditMenu, setOpenEditMenu] = useState(null);
    const [clientsData, setClientsData] = useState({});
    const [order, setOrder] = useState(null);
    const navigate = useNavigate();
    const [sortedDirection, setSortedDirection] = useState('ASC');
    const [addMore, setAddMore] = useState(false);
    const [apiSuccessData,] = apiSuccessDataGlobal;
    const [clientsLastPage, setClientsLastPage] = useState(1);
    const [clientsCurrentPage, setClientsCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [updateSearch, setUpdateSearch] = useState(0);
    const [clientType, setClientType] = useState(1);
    const [updateData, setUpdateData] = useState(1);
    const [totalClient, setTotalClients] = useState(0);
    const [totalIndiv, setTotalInd] = useState(0);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [totalNgo, setTotalNgo] = useState(0);
    const [deleteClient, setDeleteClient] = useState(null);
    const [clientCategory, setClientCategory] = useState('');
    const [legalCategory, setLegalCategory] = useState('');
    const [recordsTitle, setRecordsTitle] = useState('');
    const [popupTitle, setPopupTitle] = useState('');

    /* handle click outside to close the custom dropdown */
    const useOutsideClick = (ref, callback) => {
        const handleClick = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        useEffect(() => {
            document.addEventListener("click", handleClick);

            return () => {
                document.removeEventListener("click", handleClick);
            };
        });
    };

    useOutsideClick(ref, () => {
        setAddMore(false);
    });
    /* handle click outside to close the custom dropdown */

    //get all legal types
    const legalTypes = () => {
        const types = generalData?.legal_types?.map(
            type => ({ value: type.id, label: type.title }))
        return types;
    }

    //custom select styles
    const customStylesUsersFilter = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '500',
                textTransform: "capitalize",
                margin: "auto",
                fontSize: '14px',
                paddingRight: "4px",
                maxWidth: "100%",
                width: 'auto',
                paddingLeft: "4px",
                paddingBottom: "2px",
                paddingTop: "2px",
                backgroundColor: 'white',
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            padding: '2px',
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "1px solid transparent",
            backgroundColor: 'transparent !important',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: "none",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid white",
            marginTop: "-1px",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            opacity: "0.4",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '500',
            padding: "0",
            marginTop: "1px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle add company click
    const clickAddCompany = (event, type) => {
        event.preventDefault();
        setAddMore(false);
        setAddClient(true);
        setCurrentClient({ client_type_id: type });
    }
    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'get-clients-data?page=' + clientsCurrentPage,
            method: 'post',
            hideLoaderInPage: 1,
            data: {
                direction: sortedDirection,
                sort: order,
                search: searchValue,
                client_type: clientType,
                client_category_id_filter: clientCategory ? clientCategory?.value : '',
                legal_type_id_filter: legalCategory ? legalCategory?.value : '',
            },
            callback: res => {
                setClientsData(res.data?.clients.data);
                setClientsLastPage(res.data?.clients.last_page);
                setTotalClients(res.data['total_clients']);
                setTotalCompanies(res.data['total_company']);
                setTotalInd(res.data['total_indiv']);
                setTotalNgo(res.data['total_ngo']);
            },
            catch: err => {
            }
        });
    }, [authUser]);// eslint-disable-line react-hooks/exhaustive-deps

    //get clients data
    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'get-clients-data?page=' + clientsCurrentPage,
            method: 'post',
            data: {
                direction: sortedDirection,
                sort: order,
                search: searchValue,
                client_type: clientType,
                client_category_id_filter: clientCategory ? clientCategory?.value : '',
                legal_type_id_filter: legalCategory ? legalCategory?.value : '',
            },
            callback: res => {
                setClientsData(res.data?.clients.data);
                setClientsLastPage(res.data?.clients.last_page);
                setTotalClients(res.data['total_clients']);
                setTotalCompanies(res.data['total_company']);
                setTotalInd(res.data['total_indiv']);
                setTotalNgo(res.data['total_ngo']);
            },
            catch: err => {
            }
        });
    }, [sortedDirection, order, clientsCurrentPage, updateData, clientCategory, legalCategory]);// eslint-disable-line react-hooks/exhaustive-deps

    //reset legal category
    useEffect(() => {
        if (clientType !== 1) {
            setLegalCategory('');
        }
    }, [clientType]);

    const updateClientData = (data) => {
        if (data?.clients) {
            setClientsData(data.clients?.clients?.data);
            setClientsLastPage(data.clients.clients?.last_page);
            setTotalClients(data.clients?.total_clients);
            setTotalCompanies(data.clients?.total_company);
            setTotalInd(data.clients?.total_indiv);
            setTotalNgo(data.clients?.total_ngo);
        }
        if (data?.auth_user) {
            setAuthUser(data?.auth_user);
        }
    }

    //delete client submit
    const deleteClientProcess = (fileId) => {
        setGeneralDataApiOptions({
            url: 'delete-client',
            method: 'post',
            data: {
                direction: sortedDirection,
                sort: order,
                search: searchValue,
                client_type: clientType,
                page: clientsCurrentPage,
                client_id: fileId,
                client_category_id: clientCategory ? clientCategory?.value : '',
            },
            callback: res => {
                setClientsData(res.data?.clients.data);
                setClientsLastPage(res.data?.clients.last_page);
                setTotalClients(res.data['total_clients']);
                setTotalCompanies(res.data['total_company']);
                setTotalInd(res.data['total_indiv']);
                setTotalNgo(res.data['total_ngo']);
                setDeleteClient(null);
            },
            catch: err => {
                if (err) {
                    setOpenEditMenu(null);
                }
            }
        })
    }

    //reset open menu
    useEffect(() => {
        if (!currentClient?.id) {
            setOpenEditMenu(null);
        }
    }, [currentClient]);

    // handle update client data
    useEffect(() => {
        setClientsCurrentPage(1);
        setUpdateData(updateData + 1);
    }, [clientType]);// eslint-disable-line react-hooks/exhaustive-deps

    //get client categories
    const clientCategories = () => {
        const types = generalData?.client_categories?.map(
            type => ({ value: type.id, label: type.title }))
        return types;
    }

    //rest pagination current pages
    useEffect(() => {
        setClientsCurrentPage(1);
        setUpdateData(updateData + 1);
    }, [updateSearch]);// eslint-disable-line react-hooks/exhaustive-deps

    //update numbers
    useEffect(() => {
        if (apiSuccessData?.clients?.data) {
            if (apiSuccessData?.clients.data[0] && apiSuccessData?.clients.data[0].client_type_id === clientType) {
                setClientsData(apiSuccessData?.clients.data);
                setTotalClients(apiSuccessData['total_clients']);
                setTotalCompanies(apiSuccessData['total_company']);
                setTotalInd(apiSuccessData['total_indiv']);
                setTotalNgo(apiSuccessData['total_ngo']);
            }
        }
    }, [apiSuccessData]);// eslint-disable-line react-hooks/exhaustive-deps

    // go to route on click
    const goToRoute = (link) => {
        navigate(link);
    }

    useEffect(() => {
        if (generalData?.client_types) {
            setRecordsTitle(generalData?.client_types[0]?.title)
        }
    }, [generalData])

    return (
        <>
            <Layout activePage='contacts'>
                <>
                    {
                        authUser?.id ?
                            <>
                                {/* header starts */}
                                <div className="row g-0">
                                    <div className="col-12 col-md-12 col-lg-11 col-xl-9">
                                        <div className="row g-0 w-100">
                                            <div className="col-12 col-md-4 pe-0 pe-md-5 finances-box bg-white seperating-line">
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <div className="background-svg-finances">
                                                            <ProfileCard />
                                                        </div>
                                                    </div>
                                                    <div className="col ps-0 pe-0 d-flex flex-column my-auto">
                                                        <h1 className="big-numbers-finances m-0">{totalClient}</h1>
                                                        <h4 className="big-titles-finances m-0">{generalData?.contacts_settings['total-contacts']}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-8 finances-box bg-white seperated-div">
                                                <div className="row w-100 h-100 align-items-md-center justify-content-md-center">
                                                    <div className="col-6 col-sm-4 col-md-4 pb-2 pb-md-0 justify-content-center align-items-center">
                                                        <div className="row d-flex justify-content-center align-items-cente">
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <h1 className="big-numbers-finances">{totalIndiv}</h1>
                                                            </div>
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <IndividualIcon width={30} height={30} className="ms-2" />
                                                            </div>
                                                            <div className="row d-flex justify-content-center alig-items-center">
                                                                <div className="col-auto">
                                                                    <h2 className="big-titles-finances m-0">{generalData?.contacts_settings['individuals']}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-4 pb-2 pb-md-0 justify-content-center align-items-center">
                                                        <div className="row d-flex justify-content-center align-items-cente">
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <h1 className="big-numbers-finances">{totalCompanies}</h1>
                                                            </div>
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <CompanyIcon width={30} height={30} className="ms-2" />
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex justify-content-center alig-items-center">
                                                            <div className="col-auto">
                                                                <h2 className="big-titles-finances m-0">{generalData?.contacts_settings['companies']}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-4 col-md-4 pb-2 pb-md-0 justify-content-center align-items-center">
                                                        <div className="row d-flex justify-content-center align-items-cente">
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <h1 className="big-numbers-finances">{totalNgo}</h1>
                                                            </div>
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <NgoIcon width={30} height={30} className="ms-2" />
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex justify-content-center alig-items-center">
                                                            <div className="col-auto">
                                                                <h2 className="big-titles-finances m-0">{generalData?.contacts_settings['ngos']}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* header ends  */}

                                {/* filters starts  */}
                                <div className="row g-0 pt-4 pb-1 pb-md-4 justify-content-between">
                                    <div className="col-auto currency-box-padd my-auto d-flex flex-row font-dark-blue bg-white">
                                        <div className="row">
                                            {
                                                generalData?.client_types?.map((singleType, ind) => (
                                                    <div key={ind} className={'col-auto ' + (ind === 0 ? ' pe-0' : (ind === 1 ? 'ps-0 pe-0' : 'ps-0'))}>
                                                        <div onClick={() => { setClientType(singleType?.id); setRecordsTitle(singleType?.title) }} className={'smaller-font currency-text cursor-pointer d-flex align-items-center ' + (singleType?.id === clientType ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.client_types?.length) ? ' ' : 'me-1')}>
                                                            {
                                                                ind === 1 ?
                                                                    <IndividualIcon className="me-1 me-sm-2 me-md-2" />
                                                                    :
                                                                    ind === 0 ?
                                                                        <CompanyIcon className="me-1 me-sm-2 me-md-2" />
                                                                        :
                                                                        <NgoIcon className="me-1 me-sm-2 me-md-2" />

                                                            }
                                                            {singleType?.title}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                        <div className="row justify-content-end">
                                            <div className="col-12 col-xl-5 mt-2 mt-md-0">
                                                <input onKeyDown={(e) => (e.key === "Enter" ? setUpdateSearch(updateSearch + 1) : null)} name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                            </div>
                                            {
                                                clientType === 1 ?
                                                    <div style={{ zIndex: "998" }} className="col-auto filters-date-select position-relative mt-3 mt-xl-0">
                                                        {
                                                            legalCategory ?
                                                                <div className="svg-remove-filter" style={{ zIndex: '99999' }} onClick={(e) => { setClientsCurrentPage(1); setLegalCategory('') }} >
                                                                    <CloseIcon />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <Select
                                                            classNamePrefix="filter-picker-users"
                                                            className="w-100"
                                                            styles={customStylesUsersFilter}
                                                            options={legalTypes()}
                                                            placeholder={generalData?.contacts_settings['legal-title']}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            isSearchable={false}
                                                            value={legalCategory}
                                                            onChange={(e) => { setClientsCurrentPage(1); setLegalCategory(e) }}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div style={{ zIndex: "997" }} className="col-auto filters-date-select position-relative mt-3 mt-xl-0">
                                                {
                                                    clientCategory ?
                                                        <div className="svg-remove-filter" style={{ zIndex: '99999' }} onClick={(e) => { setClientsCurrentPage(1); setClientCategory('') }} >
                                                            <CloseIcon />
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <Select
                                                    classNamePrefix="filter-picker-users-fixed"
                                                    className="w-100"
                                                    styles={customStylesUsersFilter}
                                                    options={clientCategories()}
                                                    placeholder={generalData?.contacts_settings['category-title']}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    width="100px"
                                                    isSearchable={false}
                                                    value={clientCategory}
                                                    onChange={(e) => { setClientsCurrentPage(1); setClientCategory(e) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* filters ends  */}
                                <div className="row g-0">
                                    <div className="col-12 p-0 height-100 pb-0 w-100 costs-box-styling bg-white">
                                        <div className="table-wrapper-settings">
                                            <table className="users-table" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td width="40px" style={{ minWidth: "20px", width: "20px", maxWidth: "20px" }}></td>
                                                        <td className="table-sorting" onClick={() => { setOrder('name'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "250px", width: "250px", maxWidth: "250px" }}>{generalData?.contacts_settings['name-title']}
                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'name' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                        </td>
                                                        <td className="table-sorting" onClick={() => { setOrder('email'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "300px", width: "350px", maxWidth: "350px" }}>{generalData?.contacts_settings['email-title']}
                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'email' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                        </td>
                                                        {
                                                            clientType === 1 ?
                                                                <td className="table-sorting" onClick={() => { setOrder('legal_type_id'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>{generalData?.contacts_settings['legal-title']}
                                                                    <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'legal_type_id' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td className="table-sorting" onClick={() => { setOrder('main_phone_number'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>{generalData?.contacts_settings['phone-title']}
                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'main_phone_number' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                        </td>
                                                        <td className="table-sorting" onClick={() => { setOrder('client_category_id'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>{generalData?.contacts_settings['category-title']}
                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'client_category_id' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                        </td>
                                                        <td className="table-sorting" onClick={() => { setOrder('client_type_id'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "200px", width: "200px" }}>{generalData?.contacts_settings['type-title']}
                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'client_type_id' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                        </td>
                                                        <td className="table-sorting" onClick={() => { setOrder('internal_ref'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "300px", width: "300px" }}>{generalData?.contacts_settings['internal-ref']}
                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'internal_ref' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        clientsData?.length > 0 &&
                                                        clientsData?.map((singleClient, index) => (
                                                            <tr key={index} onClick={() => goToRoute('/client/' + singleClient?.id + '/general-info')}>
                                                                <td width="20px" className="text-end p-0 pe-2 position-relative" >
                                                                    {
                                                                        authUser?.user_role_id <= 2 ?
                                                                            <>
                                                                                <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); openEditMenu?.id === singleClient?.id ? setOpenEditMenu(null) : setOpenEditMenu(singleClient); setPopupTitle(recordsTitle) }} />
                                                                                <div style={{}} className={"settings-clickmenu first-td-buttons text-start " + (singleClient?.id === openEditMenu?.id ? ' d-block' : '')}>
                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setCurrentClient(singleClient); setAddClient(true); }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                    <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeleteClient(singleClient?.id) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>{singleClient.name}</td>
                                                                <td>{singleClient.email}</td>
                                                                {
                                                                    clientType === 1 ?
                                                                        <td>{singleClient?.legal_type?.title}</td>
                                                                        :
                                                                        null
                                                                }
                                                                <td>{singleClient?.main_phone_number}</td>
                                                                <td>{singleClient?.client_category?.title}</td>
                                                                <td>{singleClient?.client_type?.title}</td>
                                                                <td>{singleClient?.internal_ref}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                clientsData?.length === 0 &&
                                                <>
                                                    <div className="w-100 text-center mt-4">
                                                        {generalData?.fixed_titles['you-didnt-add-any-company-yet'].replace('%record%', recordsTitle)}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {
                                            clientsLastPage > 1 ?
                                                <div className="col-12 text-center py-3 d-flex justify-content-center">
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={clientsCurrentPage}
                                                        totalCount={12 * clientsLastPage}
                                                        pageSize={12}
                                                        onPageChange={(e) => setClientsCurrentPage(e)}
                                                    />
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            authUser?.user_role_id <= 2 ?
                                                <div className="col-12 pe-3 d-flex justify-content-end">
                                                    <div ref={ref} onClick={() => setAddMore(!addMore)} className={'add-button-fixed ' + (addMore ? ' active' : '')}>
                                                        <AddIcon />
                                                        <div className="menu-fixed-button">
                                                            <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); clickAddCompany(e, 1); setPopupTitle('Company') }} className="pb-0">Company</p>
                                                            <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); clickAddCompany(e, 2); setPopupTitle('Individual') }} className="pb-0">Individual</p>
                                                            <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); clickAddCompany(e, 3); setPopupTitle('NGO') }} >NGO</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            null
                    }
                    {
                        //client popup
                        addClient ?
                            <ClientPopup onSuccess={(res) => { res?.data?.clients && updateClientData(res?.data) }} returnClients={1} popupTitle={popupTitle} clientCategory={clientCategory ? clientCategory?.value : ''} clientType={clientType} page={clientsCurrentPage} sort={order} direction={sortedDirection} activePage={'contacts'} />
                            :
                            null
                    }
                    {
                        //delete client popup
                        deleteClient ?
                            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                <div className="pop-up-api-box">
                                    <CloseIcon className="cursor-pointer" onClick={() => { setDeleteClient(null); setOpenEditMenu(null) }} />
                                    <div className="text-center ">
                                        <div className="containter-fluid">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                    <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                    <button onClick={() => deleteClientProcess(deleteClient ? deleteClient : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                    <button onClick={() => { setDeleteClient(null); setOpenEditMenu(null) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                    <Api options={generalDataApiOptions} />
                </>
            </Layout>
        </>
    )
}