import { useContext, useEffect, useRef, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import DatePicker from "react-datepicker";
import { addYears, calcHeight, formatDate } from "../Helpers";
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { AttachIconInput, TrueSign, XSign } from "./Icons";
registerLocale('ar', ar)

export default function UpdatesEditInput(props) {
    const {
        authUserGlobal,
        generalDataGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        currentInputValidationsGlobal,
        changeInputGlobal,
    } = useContext(GlobalState);

    const [generalData] = generalDataGlobal;
    const [changeInput, setChangeInput] = changeInputGlobal;
    const [authUser,] = authUserGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const [, setCurrentInputValidations] = currentInputValidationsGlobal

    const textAreaRef = useRef(null);
    const lockedInputRef = useRef();
    const [, setTextAreaHeight] = useState(lockedInputRef?.current?.scrollHeight);
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();

    // reset states on input close
    const closeInputEdit = (e) => {
        setCurrentInputValue('');
        setCurrentInput('');
        props?.setErrorsReturned({});
    }

    //set current input value when input changes with value
    useEffect(() => {
        if (currentInput === 'date-description' && props?.singleRec?.id === currentInputValue.id) {
            setCurrentInputValue({
                ...currentInputValue,
                id: props?.singleRec?.id,
                firstInputValue: new Date(props?.firstInputValue),
                secondInputValue: props.secondInputValue,
            })
        }
    }, [currentInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //handle click on inputs to open the input in edit mode
    const disabledInputClick = (name, value, props = {}) => {
        if (authUser?.id === props.singleRec?.user_id || (authUser?.user_role_id === 1 || authUser?.user_role_id === 2) ) {
            if (currentInput !== name || currentInputValue.id !== value) {

                setCurrentInput(name);
                setCurrentInputValue({ ...value, ...props })
                setCurrentInputValidations(null);

            }
        }
    }

    const resizeTextArea = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        }

    };

    useEffect(resizeTextArea, [currentInputValue, currentInputValue?.secondInputValue]);

    //document handler
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            currentInputValue.thirdInputValue = files[0];
            setDocumentLink(file);
        }
    }
    return (
        <>
            {
                currentInput === props.inputName && (props.singleRec?.id === currentInputValue?.id) ?
                    <>
                        <div className="row w-100">
                            <div className={'col ' + (props?.withPadd !== 1 ? 'pe-0' : '') + (props?.positionAbsolute === 1 ? ' position-relative ' : ' ')}>
                                {
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-6 position-relative">
                                            <DatePicker
                                                className="popup-date cursor-pointer profile-general-input ms-0 datepicker"
                                                placeholderText={'date'}
                                                value={currentInputValue?.firstInputValue}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={currentInputValue?.firstInputValue ? new Date(currentInputValue?.firstInputValue) : new Date()}
                                                dateFormat="d MM yyyy"
                                                onChange={(date) => setCurrentInputValue({ ...currentInputValue, firstInputValue: formatDate(date) })}
                                                minDate={addYears(new Date(), -100)}
                                                maxDate={addYears(new Date(), +100)}
                                                locale={localStorage.getItem("BlpLanguage")}
                                            />
                                        </div>
                                        <div className="col-xl-8 col-6">
                                            <textarea
                                                value={currentInputValue?.secondInputValue}
                                                resize={true}
                                                rows={1}
                                                onChange={(e) => { setCurrentInputValue({ ...currentInputValue, secondInputValue: e.target.value }); setTextAreaHeight(calcHeight(e.target.value)) }}

                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter" && !e.shiftKey) {
                                                        e.preventDefault();
                                                        setChangeInput(changeInput + 1)
                                                    }
                                                    if (e.key === "Escape") {
                                                        closeInputEdit(e)
                                                    }
                                                }}
                                                className={`profile-general-input w-100 resizable ${props.withPadd === 1 ? '' : 'm-0 p-0'}`}
                                                style={{
                                                    resize: 'vertical',

                                                    maxHeight: 300,
                                                    minHeight: 30,
                                                }}
                                                autoFocus
                                                ref={textAreaRef}

                                                onFocus={() => { setTextAreaHeight(calcHeight(currentInputValue?.secondInputValue)) }}
                                            ></textarea>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className={'col-auto ps-2 ' + (props?.withPadd !== 1 ? 'ps-xl-0' : '')}>
                                <div className="d-flex">
                                    <div className="input-profile-buttons ms-1" onClick={(e) => setChangeInput(changeInput + 1)}>
                                        <TrueSign />
                                    </div>
                                    <div className="input-profile-buttons ms-1" onClick={(e) => closeInputEdit(e)}>
                                        <XSign />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 position-relative mb-3 pt-2 ">
                                <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                <label className="pop-upload-file position-relative cursor-pointer" htmlFor="upload-document-file">{(currentInputValue?.thirdInputValue && currentInputValue?.thirdInputValue !== 1) ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                <AttachIconInput className="attach-updates-icon" />
                                {currentInputValue?.thirdInputValue && currentInputValue?.thirdInputValue !== 1  ? (
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            currentInputValue.thirdInputValue = 1;
                                            setDocument(1);
                                            setDocumentLink(1);
                                        }}
                                        className="close-updates-icon input-profile-buttons"
                                    >
                                        <XSign />
                                    </div>
                                ) : null}
                                {
                                    props?.errorsReturned && props?.errorsReturned['file'] ?
                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{props?.errorsReturned?.file[0]}</h5>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="row">
                            {
                                props?.errorsReturned && props?.errorsReturned[props.inputName] ?
                                    <div className="col-12">
                                        <p className={'errors-returned-profile ' + (props.withPadd === 1 ? '' : ' p-0')}>{props?.errorsReturned[props.inputName]}</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className="col-12"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!props?.locked) {
                                    disabledInputClick(props.inputName, props.singleRec, props)
                                }
                            }}

                        >
                            <div className="row">
                                <div className="col">
                                    <div className="row gx-1">
                                        <div className="col-auto">


                                            <h5 className="locked-inputs">

                                                {`[${props?.firstInputValue ? props?.firstInputValue : props?.firstInputPlaceholder}]`}
                                            </h5>
                                        </div>
                                        <div className="col-auto" >

                                            <h5 className="locked-inputs " style={{ whiteSpace: 'break-spaces' }}>
                                                {props?.secondInputValue ? props?.secondInputValue : props?.secondInputPlaceholder}
                                            </h5>
                                        </div>
                                        <div className="col-12 pb-2">
                                            {
                                                props?.singleRec?.uploaded_files_id ?
                                                    <a onClick={(e) => (e.stopPropagation())} href={props?.singleRec?.uploaded_files?.file ? props?.singleRec?.uploaded_files?.full_path_file : null} target="_blank" rel="noreferrer" className="locked-inputs w-100 m-0 p-0">{props?.singleRec?.uploaded_files_id ? generalData?.website_titles['download-file'] : ''}</a>
                                                    :
                                                    <p>{generalData?.website_titles['upload-document']}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
            {
                props?.errorsReturned && props?.errorsReturned[props.inputName] && currentInput === props.inputName &&
                < div className="col-12">
                    <p className={'errors-returned-profile ' + (props.withPadd === 1 ? '' : ' p-0')}>{props?.errorsReturned[props.inputName]}</p>
                </div >
            }

        </>
    )
}