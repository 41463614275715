import { useContext, useState, useEffect, useRef } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import React from 'react';
import Layout from "../components/Layout";
import Api from "../components/Api";
import Documents from "../components/Documents";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClientCosts from "../components/ClientCosts";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ClientReminders from "../components/ClientReminders";
import IndividualGeneralInfo from "../components/IndividualGeneralInfo";
import ClientsRelations from "../components/ClientRelations";
import ReminderPopup from "../components/ReminderPopup";
import CompanyGeneralInfo from "../components/CompanyGeneralInfo";
import NgoGeneralInfo from "../components/NgoGeneralInfo";
import BoardMembers from "../components/BoardMembers";
import ClientBanks from "../components/ClientBanks";
import ClientSignatures from "../components/ClientSignatures";
import AdministrativeBody from "../components/AdministrativeBody";
import EntityManagement from "../components/EntityManagement";
import Shareholders from "../components/ShareHolder";
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { formatDate, getIndex } from '../Helpers';
import { AddIcon, AttachIcon, CloseIcon } from "../components/Icons";
import { Link } from "react-router-dom";

registerLocale('ar', ar)

export default function Profile() {

    const { generalDataGlobal, openAddReminderGlobal, authUserGlobal, profileClientGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, user_id, folder_id } = useParams();
    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const history = useNavigate();
    const [swiper, setSwiper] = useState(null);
    const [securityCheck, setSecurityCheck] = useState(0);
    const [addMore, setAddMore] = useState(false);
    const refAdd = useRef(null);
    const [openAddReminder, setOpenAddReminder] = openAddReminderGlobal;
    const [deleteClient, setDeleteClient] = useState('');

    //get all currencies
    const allCurrencies = () => {
        const curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        return curren;
    }

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            setDocumentLink(file);
        }
    }

    //get all transaction types
    const allTransactionTypes = () => {
        const transactionType = generalData?.transaction_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return transactionType;
    }

    //get all users
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: <div><img className="select-image me-1" src={singleOption?.picture ? singleOption?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt={singleOption?.name} />{singleOption?.name}</div> }))
        return curren;
    }
    /* transactions start */
    const [addTransaction, setAddTransaction] = useState(false);
    const [, setOpenEditTransaction] = useState(null);
    const [transactionType, setTransactionType] = useState(allTransactionTypes()[getIndex(5, allTransactionTypes(), 'value')]);
    const [transactionCurrency, setTransactionCurrency] = useState('');
    const [transactionTo, setTransactionTo] = useState(allUsers()[getIndex(Number(authUser?.id), allUsers(), 'value')]);
    const [currenEditTransaction, setCurrenEditTransaction] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const [addTransactionDate, setAddTransactionDate] = useState(new Date());
    const [addTransactionNote, setAddTransactionNote] = useState('');
    /* transactions start */

    // close transaction popup and reset states
    function closeAddTransaction(e) {
        e.preventDefault();
        setAddTransaction(false);
        setTransactionAmount('');
        setTransactionCurrency('');
        setAddTransactionDate(new Date());
        setCurrenEditTransaction(null);
        setAddTransactionNote('');
        if (authUser?.id !== user_id) {
            setTransactionTo(allUsers()[getIndex(Number(authUser?.id), allUsers(), 'value')]);
        }
        setErrorsReturned({});
    }

    //custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStylesUsersPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "2px",
                paddingTop: "2px",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    /* handle click outside to close custom dropdown start*/
    const useOutsideClick = (ref, callback) => {
        const handleClick = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        useEffect(() => {
            window?.document.addEventListener("click", handleClick);

            return () => {
                window?.document.removeEventListener("click", handleClick);
            };
        });
    };

    useOutsideClick(refAdd, () => {
        setAddMore(false);
    });
    /* handle click outside to close custom dropdown end*/

    /* Files Start */

    /* expenses start */
    const [addExpenses, setAddExpenses] = useState(false);
    const [currenEditExpense, setCurrenEditExpense] = useState('');
    const [addExpensesType, setAddExpensesType] = useState('');
    const [addExpensesAmount, setAddExpensesAmount] = useState('');
    const [addExpensesCurrency, setAddExpensesCurrency] = useState('');
    const [addExpensesDate, setAddExpensesDate] = useState(new Date());
    const [addExpensesNote, setAddExpensesNote] = useState('');
    const [addExpensesBillable, setAddExpensesBillable] = useState(0);
    /* expenses end */

    //submit transactions
    const submitTransactions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        fd.append('transaction_type_id', transactionType ? transactionType?.value : '');
        fd.append('currency_id', transactionCurrency ? transactionCurrency?.value : '');
        fd.append('receiver_id', transactionTo ? transactionTo?.value : '');
        fd.append('currency', 'usd');
        fd.append('user_id', user_id);
        fd.append('amount', transactionAmount ? transactionAmount : '');
        fd.append('date', addTransactionDate ? formatDate(addTransactionDate) : '');
        fd.append('note', addTransactionNote ? addTransactionNote : '');
        fd.append('client_id', user_id);


        setGeneralDataApiOptions({
            url: 'add-client-transaction',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //custom select styles
    const customStylesFiles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            minHeight: "40px",
            height: "40px",
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "40px",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const [errorsReturned, setErrorsReturned] = useState({});
    const [uploadFilePopup, setUploadFilePopup] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileComment, setFileComment] = useState('');
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();
    // const [tempFile, setTempFile] = useState();
    const [, setFileMenuOpen] = useState();
    const [editingFile, setEditingFile] = useState();

    //handle file upload documents
    const documentHandlerFiles = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            setDocumentLink(file);
        }
    }

    //submit files
    const submitFiles = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('main_directory', 2);
        fd.append('user_id', user_id);
        fd.append('folder_id', '');
        if (editingFile?.id) {
            fd.append('file_id', editingFile?.id);
        }
        fd.append('file_name', fileName);
        fd.append('files_type_id', fileType ? fileType?.value : '');
        fd.append('comment', fileComment);

        if (document) {
            fd.append('file', document[0]);
        }
        fd.append('client_id', user_id);
        fd.append('individual', 1);


        setGeneralDataApiOptions({
            url: 'submit-files',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res.data);
                setErrorsReturned({});
                setFileType('');
                setFileName('');
                setDocument('');
                setEditingFile('');
                setFileMenuOpen('');
                setDocumentLink('');
                setUploadFilePopup(false);
                setFileComment('');
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //get all file types
    const allFileTypes = () => {
        const FileTypes = generalData?.files_type?.map(
            singleFile => ({ value: singleFile.id, label: singleFile.title }))
        return FileTypes;
    }

    //close file popup and reset states
    const closeFilePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setUploadFilePopup(false);
        setFileComment('');
        setFileName('');
        setFileType('');
        setErrorsReturned({});
        setFileMenuOpen(false);
    }

    //handle access to tabs by client type
    useEffect(() => {
        if (authUser?.id && profileClient?.id) {
            if (profileClient?.client_type_id === 2) {
                if (slug !== 'general-info' && slug !== 'documents' && slug !== 'relations'
                    && slug !== 'reminder' && slug !== 'costs') {
                    history('/not-found');
                }
            }
            if (profileClient?.client_type_id === 3) {
                if (slug === 'shareholders' || slug === 'board-members') {
                    history('/not-found');
                }
            }
            if (profileClient?.client_type_id === 1) {
                if (slug === 'administrative-body') {
                    history('/not-found');
                }
            }
            if (authUser?.user_role_id !== 1) {
                if (authUser?.id !== profileClient?.id) {
                    let acc = 0;
                    if (authUser?.user_role?.role_permissions?.length > 0) {
                        authUser?.user_role?.role_permissions.map(element => {
                            if (element?.user_pages['slug'] === slug && element?.['browse_others'] === 1) {
                                acc = 1;
                            }
                            return null;
                        });
                    }
                    if (acc === 0) {
                        history('/not-found');
                    }
                }
            }
            setSecurityCheck(1);
        }
    }, [authUser, profileClient]);// eslint-disable-line react-hooks/exhaustive-deps

    // close expenses popup and reset related states
    function closeAddExpense(e) {
        e.preventDefault();
        setAddExpenses(false);
        setCurrenEditExpense('');
        setAddExpensesType('');
        setAddExpensesAmount('');
        setAddExpensesCurrency('');
        setAddExpensesDate(new Date());
        setAddExpensesNote('');
        setAddExpensesBillable('');
        setDocument();
        setDocumentLink('');
        setErrorsReturned({});
    }

    //get all expenses types
    const allExpensesTypes = () => {
        const expensesTypes = generalData?.expense_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expensesTypes;
    }

    //submit expenses
    const submitExpenses = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditExpense?.id) {
            fd.append('expense_id', currenEditExpense?.id);
        }

        fd.append('expense_type_id', addExpensesType ? addExpensesType?.value : '');
        fd.append('currency_id', addExpensesCurrency ? addExpensesCurrency?.value : '');
        fd.append('currency', 'usd');
        fd.append('user_id', user_id);
        fd.append('client_id', user_id);
        fd.append('amount', addExpensesAmount ? addExpensesAmount : '');
        fd.append('date', addExpensesDate ? formatDate(addExpensesDate) : '');
        fd.append('note', addExpensesNote ? addExpensesNote : '');
        fd.append('billable', addExpensesBillable ? addExpensesBillable : 0);

        if (document) {
            fd.append('file', document[0]);
        }

        setGeneralDataApiOptions({
            url: 'add-client-expense',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                closeAddExpense(e);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //handle swiper menu by slug selected
    useEffect(() => {
        if (slug && swiper && profileClient?.client_type_id === 2) {
            if (slug === 'general-info') {
                setTimeout(() => {
                    swiper?.slideTo(0, 400, true);
                }, 600)
            }
            if (slug === 'documents') {
                setTimeout(() => {
                    swiper?.slideTo(1, 400, true);
                }, 600)
            }
            if (slug === 'relations') {
                setTimeout(() => {
                    swiper?.slideTo(2, 400, true);
                }, 600)
            }
            if (slug === 'reminder') {
                setTimeout(() => {
                    swiper?.slideTo(3, 400, true);
                }, 600)
            }
            if (slug === 'costs') {
                setTimeout(() => {
                    swiper?.slideTo(4, 400, true);
                }, 600)
            }
        }
        if (slug && swiper && profileClient?.client_type_id === 1) {
            if (slug === 'general-info') {
                setTimeout(() => {
                    swiper?.slideTo(0, 400, true);
                }, 600)
            }
            if (slug === 'entity-management') {
                setTimeout(() => {
                    swiper?.slideTo(1, 400, true);
                }, 600)
            }
            if (slug === 'shareholders') {
                setTimeout(() => {
                    swiper?.slideTo(2, 400, true);
                }, 600)
            }
            if (slug === 'board-members') {
                setTimeout(() => {
                    swiper?.slideTo(3, 400, true);
                }, 600)
            }
            if (slug === 'documents') {
                setTimeout(() => {
                    swiper?.slideTo(4, 400, true);
                }, 600)
            }
            if (slug === 'bank-account') {
                setTimeout(() => {
                    swiper?.slideTo(5, 400, true);
                }, 600)
            }
            if (slug === 'relations') {
                setTimeout(() => {
                    swiper?.slideTo(6, 400, true);
                }, 600)
            }
            if (slug === 'signature-authority') {
                setTimeout(() => {
                    swiper?.slideTo(7, 400, true);
                }, 600)
            }
            if (slug === 'reminder') {
                setTimeout(() => {
                    swiper?.slideTo(8, 400, true);
                }, 600)
            }
            if (slug === 'costs') {
                setTimeout(() => {
                    swiper?.slideTo(9, 400, true);
                }, 600)
            }
        }

        if (slug && swiper && profileClient?.client_type_id === 3) {
            if (slug === 'general-info') {
                setTimeout(() => {
                    swiper?.slideTo(0, 400, true);
                }, 600)
            }
            if (slug === 'entity-management') {
                setTimeout(() => {
                    swiper?.slideTo(1, 400, true);
                }, 600)
            }
            if (slug === 'administrative-body') {
                setTimeout(() => {
                    swiper?.slideTo(2, 400, true);
                }, 600)
            }
            if (slug === 'documents') {
                setTimeout(() => {
                    swiper?.slideTo(3, 400, true);
                }, 600)
            }
            if (slug === 'bank-account') {
                setTimeout(() => {
                    swiper?.slideTo(4, 400, true);
                }, 600)
            }
            if (slug === 'relations') {
                setTimeout(() => {
                    swiper?.slideTo(5, 400, true);
                }, 600)
            }
            if (slug === 'signature-authority') {
                setTimeout(() => {
                    swiper?.slideTo(6, 400, true);
                }, 600)
            }
            if (slug === 'reminder') {
                setTimeout(() => {
                    swiper?.slideTo(7, 400, true);
                }, 600)
            }
            if (slug === 'costs') {
                setTimeout(() => {
                    swiper?.slideTo(8, 400, true);
                }, 600)
            }
        }
    }, [slug, swiper]);// eslint-disable-line react-hooks/exhaustive-deps

    //get client data
    useEffect(() => {
        if (authUser) {
            setGeneralDataApiOptions({
                url: 'get-client-data',
                method: 'post',
                data: {
                    user_id: user_id,
                    section: slug === 'bank-account' ? 'banks' : '',
                },
                callback: res => {
                    setProfileClient(res.data);
                },
                catch: err => {
                    if (err) {
                        history('/not-found');
                    }
                }
            })
        }
    }, [user_id, slug]);// eslint-disable-line react-hooks/exhaustive-deps


    const submitDelete = (e, submitted_id) => {
        e.preventDefault();
        e.stopPropagation();
        if (submitted_id) {
            let fd = new FormData();
            fd.append('client_id', submitted_id);
            setGeneralDataApiOptions({
                url: 'delete-client',
                method: 'post',
                data: fd,
                callback: res => {
                    setDeleteClient('');
                    setErrorsReturned({});
                    history('/contacts');
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                    }
                }
            })
        }
    };


    return (
        <>

            <Layout activePage="contacts">
                {
                    authUser?.id && profileClient?.id === Number(user_id) && securityCheck === 1 ?
                        <>
                            <div className="row">
                                <div className="col-12 col-sm-auto">
                                    <h1 style={{ textTransform: "capitalize" }} className="page-titles color-dark-blue pb-3">{profileClient?.name}</h1>
                                </div>
                                {
                                    authUser?.id && authUser?.user_role?.slug === 'admin' ?
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end pb-3">
                                                    <button onClick={() => setDeleteClient(profileClient?.id)} className="popup-button my-auto mx-2 w-auto px-3">{generalData?.website_titles['delete']}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            {
                                profileClient?.client_type_id === 2 ?
                                    <Swiper
                                        onSwiper={setSwiper}
                                        className="titles-swiper w-100 new-titles-styling-syling"
                                        // spaceBetween={20}
                                        slidesPerView={2}
                                        breakpoints={{
                                            775: {
                                                slidesPerView: 5,
                                            },
                                            575: {
                                                slidesPerView: 4,
                                            },
                                            490: {
                                                slidesPerView: 3,
                                            },
                                            355: {
                                                slidesPerView: 2
                                            }
                                        }}
                                    >
                                        <div className="row padding-top-table-settings" >
                                            <SwiperSlide className={(slug === 'general-info' ? " active-custom-slide" : "")} key={1}>
                                                <div className="col-auto">
                                                    <Link to={'/client/' + profileClient?.id + '/general-info'} >
                                                        <h2 className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'general-info' ? " active" : "")}>{generalData?.website_titles['general-info']}</h2>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className={(slug === 'documents' ? " active-custom-slide" : "")} key={2}>
                                                <div className="col-auto">
                                                    <Link to={'/client/' + profileClient?.id + '/documents'}>
                                                        <h2 className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'documents' ? " active" : "")}>{generalData?.website_titles['documents-individual']}</h2>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                            {
                                                authUser?.user_role_id <= 2 ?
                                                    <SwiperSlide className={(slug === 'relations' ? " active-custom-slide" : "")} key={3}>
                                                        <div className="col-auto">
                                                            <Link to={'/client/' + profileClient?.id + '/relations'} >
                                                                <h2 className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'relations' ? " active" : "")}>{generalData?.website_titles['relations']}</h2>
                                                            </Link>
                                                        </div>
                                                    </SwiperSlide>
                                                    :
                                                    null
                                            }
                                            <SwiperSlide className={(slug === 'reminder' ? " active-custom-slide" : "")} key={4}>
                                                <div className="col-auto">
                                                    <Link to={'/client/' + profileClient?.id + '/reminder'} >
                                                        <h2 className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'reminder' ? " active" : "")}>{generalData?.website_titles['reminder']}</h2>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className={(slug === 'costs' ? " active-custom-slide" : "")} key={5}>
                                                <div className="col-auto">
                                                    <Link to={'/client/' + profileClient?.id + '/costs'}>
                                                        <h2 className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'costs' ? " active" : "")}>{generalData?.website_titles['costs']}</h2>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        </div>
                                    </Swiper>
                                    :
                                    profileClient?.client_type_id === 1 ?
                                        <Swiper
                                            onSwiper={setSwiper}
                                            className="big-swiper w-100 titles-swiper new-titles-styling-syling"
                                            // spaceBetween={30}
                                            slidesPerView={"auto"}
                                            centeredSlides={false}
                                            resistanceRatio={0}
                                        >
                                            <div className="row padding-top-table-settings" >
                                                <SwiperSlide className={(slug === 'general-info' ? " active-custom-slide" : "")} key={1}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/general-info')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'general-info' ? " active" : "")}>{generalData?.website_titles['general-info']}</h2>
                                                </SwiperSlide>
                                                <SwiperSlide className={(slug === 'entity-management' ? " active-custom-slide" : "")} key={2}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/entity-management')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'entity-management' ? " active" : "")}>{generalData?.website_titles['entity-management']}</h2>
                                                </SwiperSlide>
                                                {
                                                    authUser?.user_role_id <= 2 ?
                                                        <SwiperSlide className={(slug === 'shareholders' ? " active-custom-slide" : "")} key={3}>
                                                            <h2 onClick={() => history('/client/' + profileClient?.id + '/shareholders')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'shareholders' ? " active" : "")}>{generalData?.website_titles['shareholders']}</h2>
                                                        </SwiperSlide>
                                                        :
                                                        null
                                                }
                                                {
                                                    authUser?.user_role_id <= 2 ?
                                                        <SwiperSlide className={(slug === 'board-members' ? " active-custom-slide" : "")} key={4}>
                                                            <h2 onClick={() => history('/client/' + profileClient?.id + '/board-members')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'board-members' ? " active" : "")}>{generalData?.website_titles['board-members']}</h2>
                                                        </SwiperSlide>
                                                        :
                                                        null
                                                }
                                                <SwiperSlide className={(slug === 'documents' ? " active-custom-slide" : "")} key={5}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/documents')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'documents' ? " active" : "")}>{generalData?.website_titles['documents-individual']}</h2>
                                                </SwiperSlide>
                                                {
                                                    authUser?.user_role_id <= 2 ?
                                                        <SwiperSlide className={(slug === 'bank-account' ? " active-custom-slide" : "")} key={6}>
                                                            <h2 onClick={() => history('/client/' + profileClient?.id + '/bank-account')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'bank-account' ? " active" : "")}>{generalData?.website_titles['bank-account']}</h2>
                                                        </SwiperSlide>
                                                        :
                                                        null
                                                }
                                                <SwiperSlide className={(slug === 'relations' ? " active-custom-slide" : "")} key={7}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/relations')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'relations' ? " active" : "")}>{generalData?.website_titles['relations']}</h2>
                                                </SwiperSlide>
                                                {
                                                    authUser?.user_role_id <= 2 ?
                                                        <SwiperSlide className={(slug === 'signature-authority' ? " active-custom-slide" : "")} key={8}>
                                                            <h2 onClick={() => history('/client/' + profileClient?.id + '/signature-authority')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'signature-authority' ? " active" : "")}>{generalData?.website_titles['signature-authority']}</h2>
                                                        </SwiperSlide>
                                                        :
                                                        null
                                                }
                                                <SwiperSlide className={(slug === 'reminder' ? " active-custom-slide" : "")} key={9}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/reminder')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'reminder' ? " active" : "")}>{generalData?.website_titles['reminder']}</h2>
                                                </SwiperSlide>
                                                <SwiperSlide className={(slug === 'costs' ? " active-custom-slide" : "")} key={10}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/costs')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'costs' ? " active" : "")}>{generalData?.website_titles['costs']}</h2>
                                                </SwiperSlide>
                                            </div>
                                        </Swiper>
                                        :
                                        <Swiper
                                            onSwiper={setSwiper}
                                            className="big-swiper w-100 titles-swiper new-titles-styling-syling"
                                            // spaceBetween={30}
                                            slidesPerView={"auto"}
                                            centeredSlides={false}
                                            resistanceRatio={0}
                                        >
                                            <div className="row padding-top-table-settings" >
                                                <SwiperSlide className={(slug === 'general-info' ? " active-custom-slide" : "")} key={1}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/general-info')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'general-info' ? " active" : "")}>{generalData?.website_titles['general-info']}</h2>
                                                </SwiperSlide>
                                                <SwiperSlide className={(slug === 'entity-management' ? " active-custom-slide" : "")} key={2}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/entity-management')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'entity-management' ? " active" : "")}>{generalData?.website_titles['entity-management']}</h2>
                                                </SwiperSlide>
                                                {
                                                    authUser?.user_role_id <= 2 ?
                                                        <SwiperSlide className={(slug === 'administrative-body' ? " active-custom-slide" : "")} key={3}>
                                                            <h2 onClick={() => history('/client/' + profileClient?.id + '/administrative-body')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'administrative-body' ? " active" : "")}>{generalData?.website_titles['administrative-body']}</h2>
                                                        </SwiperSlide>
                                                        :
                                                        null
                                                }
                                                <SwiperSlide className={(slug === 'documents' ? " active-custom-slide" : "")} key={4}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/documents')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'documents' ? " active" : "")}>{generalData?.website_titles['documents-individual']}</h2>
                                                </SwiperSlide>
                                                {
                                                    authUser?.user_role_id <= 2 ?
                                                        <SwiperSlide className={(slug === 'bank-account' ? " active-custom-slide" : "")} key={5}>
                                                            <h2 onClick={() => history('/client/' + profileClient?.id + '/bank-account')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'bank-account' ? " active" : "")}>{generalData?.website_titles['bank-account']}</h2>
                                                        </SwiperSlide>
                                                        :
                                                        null
                                                }
                                                <SwiperSlide className={(slug === 'relations' ? " active-custom-slide" : "")} key={6}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/relations')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'relations' ? " active" : "")}>{generalData?.website_titles['relations']}</h2>
                                                </SwiperSlide>

                                                {
                                                    authUser?.user_role_id <= 2 ?
                                                        <SwiperSlide className={(slug === 'signature-authority' ? " active-custom-slide" : "")} key={7}>
                                                            <h2 onClick={() => history('/client/' + profileClient?.id + '/signature-authority')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'signature-authority' ? " active" : "")}>{generalData?.website_titles['signature-authority']}</h2>
                                                        </SwiperSlide>
                                                        :
                                                        null
                                                }
                                                <SwiperSlide className={(slug === 'reminder' ? " active-custom-slide" : "")} key={8}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/reminder')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'reminder' ? " active" : "")}>{generalData?.website_titles['reminder']}</h2>
                                                </SwiperSlide>
                                                <SwiperSlide className={(slug === 'costs' ? " active-custom-slide" : "")} key={9}>
                                                    <h2 onClick={() => history('/client/' + profileClient?.id + '/costs')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'costs' ? " active" : "")}>{generalData?.website_titles['costs']}</h2>
                                                </SwiperSlide>
                                            </div>
                                        </Swiper>
                            }

                            <div className="management-table zi-1 pt-0">
                                {
                                    (slug === 'general-info') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id === 2 ?
                                        <>
                                            <IndividualGeneralInfo />
                                        </>
                                        :
                                        (slug === 'general-info') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id === 1 ?
                                            <CompanyGeneralInfo />
                                            :
                                            (slug === 'general-info') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id === 3 ?
                                                <NgoGeneralInfo />
                                                :
                                                null
                                }
                                {
                                    ((slug === 'entity-management') && (profileClient?.id === Number(user_id))) && (profileClient?.client_type_id === 1 || profileClient?.client_type_id === 3) ?
                                        <EntityManagement />
                                        :
                                        null
                                }
                                {
                                    (slug === 'shareholders') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id === 1 ?
                                        <Shareholders />
                                        :
                                        null
                                }
                                {
                                    (slug === 'administrative-body') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id === 3 ?
                                        <AdministrativeBody user_id={user_id} />
                                        :
                                        null
                                }
                                {
                                    (slug === 'board-members') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id === 1 ?
                                        <BoardMembers user_id={user_id} />
                                        :
                                        null
                                }
                                {
                                    slug === 'documents' ?
                                        <div className="col-12 pt-4">
                                            <Documents currentFolder={folder_id} currentUser={user_id} mainFolder={2} mainRoute={'client'} subRoute={'documents'} user_id={user_id} />
                                        </div>
                                        :
                                        null
                                }
                                {
                                    (slug === 'bank-account') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id !== 2 ?
                                        <ClientBanks clientId={user_id} />
                                        :
                                        null
                                }
                                {
                                    (slug === 'relations') ?
                                        <>
                                            <ClientsRelations returnClient={true} user_id={user_id} data={profileClient} setData={setProfileClient} />
                                        </>
                                        :
                                        null
                                }
                                {
                                    (slug === 'signature-authority') && (profileClient?.id === Number(user_id)) && profileClient?.client_type_id !== 2 ?
                                        <ClientSignatures clientId={user_id} />
                                        :
                                        null
                                }
                                {
                                    slug === 'reminder' ?
                                        <ClientReminders marks={profileClient?.marks} clientId={user_id} />
                                        :
                                        null
                                }
                                {
                                    slug === 'costs' ?
                                        <>
                                            <ClientCosts />
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </>
                        :
                        null
                }
                {
                    authUser?.user_role_id <= 2 ?
                        <div className="col-12 pe-3 d-flex justify-content-end hzi-9999">
                            <div ref={refAdd} onClick={() => setAddMore(!addMore)} className={'add-button-fixed ' + (addMore ? ' active' : '')}>
                                <AddIcon />
                                <div className="menu-fixed-button">
                                    {
                                        slug !== 'documents' ?
                                            <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAddMore(false); setUploadFilePopup(true); }} className="pb-0">Document</p>
                                            :
                                            null
                                    }
                                    {
                                        slug !== 'reminder' ?
                                            <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAddMore(false); setOpenAddReminder(true); }} className={slug === 'costs' ? '' : ' pb-0'}>Reminder</p>
                                            :
                                            null
                                    }
                                    {
                                        slug !== 'costs' ?
                                            <>
                                                <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAddMore(false); setAddExpenses(true); }} className="pb-0">Expense</p>
                                                <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAddMore(false); setAddTransaction(true); }} >Fees</p>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //reminder popup
                    openAddReminder ?
                        <ReminderPopup selectedDate={new Date()} clientId={Number(user_id)} />
                        :
                        null
                }
                {
                    // upload files popup
                    uploadFilePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeFilePopup(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{editingFile?.id ? generalData?.documentsSectionSettings['edit-file-title'] : generalData?.documentsSectionSettings['upload-file-title']}</h1>
                                            </div>
                                            <form onSubmit={submitFiles}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row justify-content-start">
                                                        <div className="col-12 col-md-6 pt-2 pe-md-0">
                                                            <div className="postion-relative d-flex justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesFiles}
                                                                    options={allFileTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.documentsSectionSettings['type-placeholder']}
                                                                    isSearchable={false}
                                                                    value={fileType ? fileType : null}
                                                                    onChange={setFileType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.files_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.files_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input name="file_name" className="add-user-inputs" placeholder={generalData?.documentsSectionSettings['file-name-placeholder']} value={fileName} onChange={(e) => setFileName(e.target.value)} />
                                                            {
                                                                errorsReturned?.file_name ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 pe-md-0 position-relative">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandlerFiles(e.target.files) }} />
                                                            <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                            <AttachIcon className="download-icon" />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="file_comment" className="add-user-inputs" placeholder={generalData?.documentsSectionSettings['comment-placeholder']} value={fileComment} onChange={(e) => setFileComment(e.target.value)} />
                                                            {
                                                                errorsReturned?.file_comment ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_comment}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{editingFile?.id ? generalData?.documentsSectionSettings['change-file-button'] : generalData?.documentsSectionSettings['add-file-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //expenses popup
                    addExpenses ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddExpense(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-title'] : generalData?.users_cost_settings['add-expenses-title']}</h1>
                                            </div>
                                            <form onSubmit={submitExpenses}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allExpensesTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['expenses-type']}
                                                                    isSearchable={false}
                                                                    value={addExpensesType ? addExpensesType : null}
                                                                    onChange={setAddExpensesType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.expense_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.expense_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['currency']}
                                                                    isSearchable={false}
                                                                    value={addExpensesCurrency ? addExpensesCurrency : null}
                                                                    onChange={setAddExpensesCurrency} />
                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input name="amount" value={addExpensesAmount} onChange={(e) => setAddExpensesAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={'Date'}
                                                                    value={addExpensesDate ? addExpensesDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addExpensesDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddExpensesDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                            <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                            <AttachIcon className="download-icon download-svg-popup" />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addExpensesNote} onChange={(e) => setAddExpensesNote(e.target.value)}></textarea>
                                                        </div>
                                                        <div className="col-12 pt-2 d-flex my-auto">
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className={'my-auto billable-div ' + (addExpensesBillable ? 'active' : '')}>
                                                            </div>
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className="ms-2 billable-popup">{generalData?.users_cost_settings['billable']}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-button'] : generalData?.users_cost_settings['add-expenses-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //transactions popup
                    addTransaction ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddTransaction(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-title'] : generalData?.users_cost_settings['add-transaction-title']}</h1>
                                            </div>
                                            <form onSubmit={submitTransactions}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allTransactionTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-type']}
                                                                    isSearchable={false}
                                                                    isDisabled={true}
                                                                    value={transactionType}
                                                                    onChange={setTransactionType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.transaction_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.transaction_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <input name="amount" value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                                <Select
                                                                    classNamePrefix="curr-select"
                                                                    className="curr-select w-100"
                                                                    styles={customStylesPopupCurr}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['curr-transaction']}
                                                                    isSearchable={false}
                                                                    value={transactionCurrency ? transactionCurrency : null}
                                                                    onChange={setTransactionCurrency} />
                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.users_cost_settings['date']}
                                                                    value={addTransactionDate ? addTransactionDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addTransactionDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddTransactionDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesUsersPopup}
                                                                    options={allUsers()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-to']}
                                                                    isSearchable={false}
                                                                    isDisabled={true}
                                                                    value={transactionTo ? transactionTo : null}
                                                                    onChange={setTransactionTo} />
                                                            </div>
                                                            {
                                                                errorsReturned?.receiver_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.receiver_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addTransactionNote} onChange={(e) => setAddTransactionNote(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-button'] : generalData?.users_cost_settings['add-transaction-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }


                {
                    // once delete is pressed we show the popup
                    deleteClient ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => setDeleteClient('')} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={(e) => submitDelete(e, deleteClient)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={(e) => setDeleteClient('')} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                <Api options={usersApiOptions} />
                <Api options={generalDataApiOptions} />
            </Layout >
        </>
    )
}