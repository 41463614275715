import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import React, { useCallback } from 'react';
import Api from "./Api";
import EditInput from "./EditInput";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import getCroppedImg from './CropImage';
import Cropper from 'react-easy-crop';
import DocumentsSmallTab from "./DocumentsSmallTab";
import ClientComments from "./ClientComments";
import { getIndex } from "../Helpers";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import LogsComponent from "./LogsComponent";
import { AddIconLt, CloseIcon, RemoveFd, SmallPencil } from "./Icons";
registerLocale('ar', ar)

export default function NgoGeneralInfo(props) {

    const { generalDataGlobal,
        authUserGlobal,
        changeInputGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        profileClientGlobal,
        returnedFiles, setReturnedFiles,
    } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, user_id } = useParams();
    const [generalData] = generalDataGlobal;
    const [authUser] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const history = useNavigate();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [profilePicture, setProfilePicture] = useState();
    const [changeInput, setChangeInput] = changeInputGlobal;
    const [uploadImagePopup, setUploadImagePopup] = useState(false);
    const [formData, setFormData] = useState({});
    const [userNationality, setUserNationality] = useState();
    const [deletePopup, setDeletePopup] = useState(null);
    const [tempPicture, setTempPicture] = useState('');

    const [croppedFinalImage, setCroppedFinalImage] = useState(null);

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation,] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState('');

    //handle crop end
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //handle showing cropped image
    const showCroppedImage = useCallback(async () => {
        let imageToCrop = (tempPicture ? tempPicture : (profileClient?.picture ? profileClient?.full_path_picture : null));
        try {
            const croppedImage = await getCroppedImg(
                imageToCrop,
                croppedAreaPixels,
                rotation
            )
            setCroppedFinalImage(croppedImage);
        } catch (e) {

        }
    }, [croppedAreaPixels, rotation, tempPicture]);// eslint-disable-line react-hooks/exhaustive-deps

    //target image change backend call once cropped image is done
    useEffect(() => {
        if (croppedFinalImage) {
            setProfilePicture(croppedFinalImage);
            setCurrentInputValue(croppedFinalImage);

            setTimeout(() => {
                setChangeInput(1);
            }, 100)
        }
    }, [croppedFinalImage]);// eslint-disable-line react-hooks/exhaustive-deps


    //get all nationalities
    const allNationalities = () => {
        const natOptions = generalData?.nationalities?.map(
            singleNat => ({ value: singleNat.id, label: singleNat.title }))
        return natOptions;
    }

    useEffect(() => {
        if (slug) {
            history('/client/' + user_id + '/' + slug);
        }
    }, [slug]);// eslint-disable-line react-hooks/exhaustive-deps

    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '16px',
                color: '#707070 !important',
                border: 'none !important',
                minHeight: "26px",
                maxHeight: "26px",
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#707070",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            minHeight: "26px",
            maxHeight: "26px",
            textTransform: "capitalize",
        }),
        control: (base, provided) => ({
            ...base,
            border: "0 !important",
            boxShadow: 'none !important',
            minHeight: '26px !important',
            height: '26px !important',
            '&:hover': { borderColor: 'transparent', border: 'none' },
            backgroundColor: 'white',
            outline: "none",
            width: "190px",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
            maxWidth: '250px',
            marginTop: '0px',
            boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            padding: "4px",
            paddingTop: "1px",
        }),
        container: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        input: (base, state) => ({
            ...base,
            backgroundColor: "transparent !important",
            width: 'auto',
            textTransform: "none !important",
            border: "none !important",
            color: "#707070",
            fontSize: "16px",
            height: "23px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle profile picture change
    const profilePictureHandler = (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
            setProfilePicture(uploadedFiles[0]);
            setCurrentInput('picture');
            setCurrentInputValue(uploadedFiles[0]);
            let file = URL.createObjectURL(uploadedFiles[0]);
            setTempPicture(file);
        }
    }

    //target remove profile picture backend call
    const removePicture = (e) => {
        setProfilePicture(null);
        setCurrentInput('picture');
        setCurrentInputValue('');
        setChangeInput(1);
        setUploadImagePopup(false);
    }

    //target nationalities backend call   
    useEffect(() => {
        if (userNationality) {
            setCurrentInput('nationality_id');
            setCurrentInputValue(userNationality);
        }
    }, [userNationality]);// eslint-disable-line react-hooks/exhaustive-deps

    //set states with current profile client data  
    useEffect(() => {
        setFormData(profileClient);
        setUserNationality(allNationalities()[getIndex(profileClient?.nationality_id, allNationalities(), 'value')]);
    }, [profileClient, user_id]);// eslint-disable-line react-hooks/exhaustive-deps

    //target backend call to save input changes
    useEffect(() => {
        if (changeInput > 0 && slug === 'general-info') {
            let fd = new FormData();
            fd.append('current_user', user_id);
            fd.append('current_field', currentInput);
            if (currentInput === 'emails' || currentInput === 'phones' || currentInput === 'addresses' || currentInput === 'websites') {
                fd.append('current_field_value', [currentInputValue['id'], currentInputValue['value']]);
            } else {
                fd.append('current_field_value', currentInputValue);
            }
            fd.append('name', formData?.name ? formData?.name : '');
            fd.append('nickname', formData?.nickname ? formData?.nickname : '');
            fd.append('foreign_name', formData?.foreign_name ? formData?.foreign_name : '');
            fd.append('picture', profilePicture ? profilePicture : '');
            fd.append('internal_ref', formData?.internal_ref ? formData?.internal_ref : '');
            fd.append('scope_of_work', formData?.scope_of_work ? formData?.scope_of_work : '');
            fd.append('nationality_id', userNationality ? userNationality.value : null);
            fd.append('user_id', user_id);


            fd.append('force', 1);
            fd.append('file_id', formData?.uploaded_files_id ? formData?.uploaded_files_id : '');

            fd.append('parent_folder_id', '');
            fd.append('files_type_id', 5);
            fd.append('main_directory', 2);
            fd.append('file_name', 'ID/Passport');
            fd.append('folder_name', 'Personal Documents');
            fd.append('client_id', user_id);
            if (currentInput === 'document') {
                fd.append('file', currentInputValue);
                fd.append('from_home', 1);
            }
            setGeneralDataApiOptions({
                url: 'edit-client-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setFormData(res?.data);
                    setProfileClient(res.data);
                    setCurrentInputValue('');
                    setCurrentInput('');
                    setErrorsReturned({});
                    setDeletePopup();
                    setChangeInput(0);
                    setUploadImagePopup(false);
                    setTempPicture('');
                    setZoom(1);
                },
                catch: err => {
                    if (err) {
                        if (currentInput === 'nationalities') {
                            setFormData(profileClient);
                        }
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //remove from dynamic inputs
    const removeFormData = (single, index, key) => {
        setErrorsReturned({});
        if (key === 'nationalities') {
            if (!formData?.nationalities[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'emails') {
            if (!formData?.emails[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'phones') {
            if (!formData?.phones[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'addresses') {
            if (!formData?.addresses[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'websites') {
            if (!formData?.websites[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
    }

    //delete dynamic inputs already fetched from backend
    const deleteElement = () => {
        if (deletePopup) {
            if (deletePopup['key'] === 'nationalities') {
                setCurrentInput('delete_nationalities');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'emails') {
                setCurrentInput('delete_emails');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'phones') {
                setCurrentInput('delete_phones');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'addresses') {
                setCurrentInput('delete_addresses');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'websites') {
                setCurrentInput('delete_websites');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
        }
    }

    // add a dynamic field to dynamic formdata
    const addToFormData = (key) => {
        let emptyObject = {};
        let added = 1;
        if (key === 'nationalities') {
            if (!profileClient?.nationality_id) {
                added = 0;
            }
            if (formData?.nationalities?.length > 0) {
                formData?.nationalities?.forEach(single => {
                    if (single?.nationality_id === null || single?.nationality_id === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: null, nationality_id: null };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }


        if (key === 'emails') {
            if (!profileClient?.email) {
                added = 0;
            }
            if (formData?.emails?.length > 0) {
                formData?.emails?.forEach(single => {
                    if (single?.email === null || single?.email === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, email: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'phones') {
            if (!profileClient?.main_phone_number) {
                added = 0;
            }
            if (formData?.phones?.length > 0) {
                formData?.phones?.forEach(single => {
                    if (single?.phone === null || single?.phone === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, phone: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'addresses') {
            if (!profileClient?.main_address) {
                added = 0;
            }
            if (formData?.addresses?.length > 0) {
                formData?.addresses?.forEach(single => {
                    if (single?.address === null || single?.address === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, address: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'websites') {
            if (!profileClient?.website) {
                added = 0;
            }
            if (formData?.websites?.length > 0) {
                formData?.websites?.forEach(single => {
                    if (single?.website === null || single?.website === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, website: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }
    }


    //handle dynamic fields changes
    const handleDynamicInputChange = (e, single, index, key) => {
        let formDt = { ...formData };
        if (key === 'nationalities') {
            formData.nationalities[index].nationality_id = e.value;
        }
        setFormData(formDt);
        setCurrentInput('nationalities');
        setCurrentInputValue([
            single.id ? single?.id : 0,
            e.value
        ]);
        setChangeInput(1);
    }

    //cancel delete popup
    const cancelDeletePopup = () => {
        setDeletePopup();
    }

    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'directories-list',
            method: 'post',
            data: {
                user_id: user_id,
                all: true,
                noEmptyComment: true,
                orderByDate: true,
                main_directory: 2,
                showLoader: false,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
            },
            catch: err => {
                if (err) {
                }
            }
        })
    }, [profileClient]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid">
                <div className="row client-profile-height">
                    <div className="col-12 col-lg-4 pt-4 drop-shadow-div scrollable-client-profile-height">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center pb-3">
                                <div onClick={() => authUser?.user_role_id <= 2 ? setUploadImagePopup(true) : null} className="position-relative">
                                    <img className="profile-image-general" src={formData.picture ? formData.full_path_picture : generalData?.fixed_settings?.full_path_no_ngo_picture} alt="profile" />
                                    {
                                        authUser?.user_role_id <= 2 ?
                                            <div className="edit-prof-back">
                                                <SmallPencil />
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            {
                                errorsReturned && errorsReturned?.current_field_value && currentInput === 'picture' ?
                                    <div className="col-12 my-auto align-items-center pb-4">
                                        <h5 className="pop-errors-text">{errorsReturned?.current_field_value}</h5>
                                    </div>
                                    :
                                    null
                            }
                            <div className="col-12 my-auto align-items-center pb-4">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-lg-12 col-xl-6 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['name']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput withPadd={1} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.name ? formData?.name : ''} inputName={"name"} userId={user_id} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.name ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-12 col-xl-6 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img style={{ opacity: '0' }} className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="nickname" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['nickname']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput placeholder={generalData?.website_titles['nickname']} withPadd={1} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.nickname ? formData?.nickname : ''} inputName={"nickname"} userId={user_id} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.nickname ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.nickname}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img style={{ opacity: '0' }} className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="foreign_name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['foreign-name']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput placeholder={generalData?.website_titles['foreign-name']} withPadd={1} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.foreign_name ? formData?.foreign_name : ''} inputName={"foreign_name"} userId={user_id} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.foreign_name ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.foreign_name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/nationality.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['nationality']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => addToFormData('nationalities')}>
                                                        <AddIconLt style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                    </div>
                                                    <div style={{ marginLeft: "-4px", marginRight: "-4px" }} className="col-10 ps-0">
                                                        <Select
                                                            isSearchable={true}
                                                            classNamePrefix="profile-select"
                                                            className="profile-select w-100 my-auto"
                                                            styles={customStyles}
                                                            options={allNationalities()}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            isDisabled={authUser?.user_role_id <= 2 ? false : true}
                                                            placeholder={generalData?.website_titles['nationality']}
                                                            value={userNationality ? userNationality : null}
                                                            onChange={(e) => { setUserNationality(e); setTimeout(() => { setChangeInput(1) }, 100) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.nationalities?.length > 0 ?
                                                    formData?.nationalities?.map((singleNat, index) => (
                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => removeFormData(singleNat, index, 'nationalities')}>
                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                </div>
                                                                <div style={{ marginLeft: "-4px", marginRight: "-4px" }} className="col-10 ps-0">
                                                                    <Select
                                                                        classNamePrefix="profile-select"
                                                                        className="profile-select w-100 my-auto"
                                                                        styles={customStyles}
                                                                        options={allNationalities()}
                                                                        components={{
                                                                            IndicatorSeparator: () => null
                                                                        }}
                                                                        placeholder={generalData?.website_titles['nationality']}
                                                                        isSearchable={true}
                                                                        value={singleNat ? allNationalities()[getIndex(singleNat?.nationality_id, allNationalities(), 'value')] : null}
                                                                        onChange={(e) => handleDynamicInputChange(e, singleNat, index, 'nationalities')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                (errorsReturned && errorsReturned?.nationalities) || (errorsReturned && errorsReturned?.nationality_id) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationalities}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationality_id}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/emails.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['email']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('emails')}>
                                                        <AddIconLt style={{ marginLeft: "2px !important" }} />
                                                    </div>
                                                    <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                        <EditInput placeholder={generalData?.website_titles['enter-email']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.email ? formData?.email : ''} inputName={"email"} userId={user_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.emails?.length > 0 ?
                                                    formData?.emails?.map((singleEmail, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => removeFormData(singleEmail, index, 'emails')}>
                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                </div>
                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput placeholder={generalData?.website_titles['enter-email']} className="w-100" singleRec={singleEmail} withPadd={0} inputType={'text'} data={formData} value={singleEmail?.email ? singleEmail?.email : ''} inputName={"emails"} userId={user_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }

                                            {
                                                errorsReturned && errorsReturned?.email ?
                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.email}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && errorsReturned?.emails ?
                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.emails}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/phone.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['phone']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('phones')}>
                                                        <AddIconLt style={{ marginLeft: "2px !important" }} />
                                                    </div>
                                                    <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                        <EditInput placeholder={generalData?.website_titles['enter-phone']} inputType={'phones'} postUrl="edit-client-data" data={profileClient} value={formData?.main_phone_number ? formData?.main_phone_number : ''} inputName={"main_phone_number"} userId={user_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.phones?.length > 0 ?
                                                    formData?.phones?.map((singlePhone, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(singlePhone, index, 'phones')}>
                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                </div>
                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput placeholder={generalData?.website_titles['enter-phone']} className="w-100" singleRec={singlePhone} withPadd={0} inputType={'phones'} data={formData} value={singlePhone?.phone ? singlePhone?.phone : ''} inputName={"phones"} userId={user_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && errorsReturned?.phones ?
                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.phones}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/location.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['address']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('addresses')}>
                                                        <AddIconLt style={{ marginLeft: "2px !important" }} />
                                                    </div>
                                                    <div style={{ marginRight: "-4px" }} className="col ps-0 pe-4">
                                                        <EditInput placeholder={generalData?.website_titles['enter-address']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.main_address ? formData?.main_address : ''} inputName={"main_address"} userId={user_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.addresses?.length > 0 ?
                                                    formData?.addresses?.map((singleAdd, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(singleAdd, index, 'addresses')}>
                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                </div>
                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput placeholder={generalData?.website_titles['enter-address']} className="w-100" singleRec={singleAdd} withPadd={0} inputType={'text'} data={formData} value={singleAdd?.address ? singleAdd?.address : ''} inputName={"addresses"} userId={user_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && (errorsReturned?.addresses || errorsReturned?.main_address) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.addresses}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.main_address}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/location.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['website']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('websites')}>
                                                        <AddIconLt style={{ marginLeft: "2px !important" }} />
                                                    </div>
                                                    <div style={{ marginRight: "-4px" }} className="col ps-0 pe-4">
                                                        <EditInput placeholder={generalData?.website_titles['website']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.website ? formData?.website : ''} inputName={"website"} userId={user_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.websites?.length > 0 ?
                                                    formData?.websites?.map((singleWeb, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(singleWeb, index, 'websites')}>
                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                </div>
                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput placeholder={generalData?.website_titles['website']} className="w-100" singleRec={singleWeb} withPadd={0} inputType={'text'} data={formData} value={singleWeb?.website ? singleWeb?.website : ''} inputName={"websites"} userId={user_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && (errorsReturned?.websites || errorsReturned?.website) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.websites}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.website}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-8 pt-4 px-lg-4 pb-5 scrollable-client-profile-height">

                        <div className="row">
                            <div className="col-auto">
                                <h1 className="profile-big-title">{generalData?.website_titles['additional-info']}</h1>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-md-6 pb-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['scope-of-work']}</p>
                                <EditInput placeholder={generalData?.website_titles['scope-of-work']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={profileClient?.scope_of_work ? profileClient?.scope_of_work : ''} inputName={"scope_of_work"} userId={user_id} />
                            </div>
                            <div className="col-12 col-md-6 pb-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['internal-ref-title']}</p>
                                <div className="row">
                                    <div className="col-12 col-lg-10 col-xl-8">
                                        <EditInput placeholder={generalData?.website_titles['internal-ref-title']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={profileClient?.internal_ref ? profileClient?.internal_ref : ''} inputName={"internal_ref"} userId={user_id} />
                                        {
                                            errorsReturned && (errorsReturned?.internal_ref) ?
                                                <>
                                                    <p className="m-0 errors-auth-profile">{errorsReturned?.internal_ref}</p>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DocumentsSmallTab userId={user_id} files={profileClient?.home_files} />


                        <ClientComments clientId={user_id} section={'general-info'} />

                        <div className="row py-2">
                            <div className="d-flex justify-content-between">
                                <h1 className="profile-big-title">
                                    {generalData?.documentsSectionSettings['view-history']}
                                </h1>
                                {
                                    returnedFiles?.length > 0 &&
                                    <a href={`/client/${user_id}/documents/history`}>{generalData?.fixed_titles['view-all']}</a>
                                }
                            </div>
                            <LogsComponent generalData={generalData} showCount={5} returnedFiles={returnedFiles} />
                            {/* <div className="col-12 mb-4 mt-4">
                                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                    {
                                        returnedFiles.length > 0 ?
                                            returnedFiles.map((file, index) => (
                                                index < 5 &&
                                                <div key={index} className="col-12">
                                                    <div className="row pb-3 flex-wrap">
                                                        <div className="col-auto position-relative">
                                                            <div className="blue-dot-logs" />
                                                            {
                                                                returnedFiles?.length !== index + 1 && index + 1 < 5 &&
                                                                <div className="blue-dot-logs-lines" ></div>

                                                            }
                                                        </div>
                                                        <div className="col ps-0">
                                                            <div className="row flex-wrap">
                                                                <div className="col">
                                                                    {
                                                                        file?.deleted_at ?
                                                                            <div className="d-flex align-items-md-center flex-md-row flex-column ">
                                                                                <del>

                                                                                    {
                                                                                        file?.comment ?
                                                                                            <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap ">
                                                                                                <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                    {file?.comment}
                                                                                                </p>

                                                                                                <a target='_blank' href={file?.full_path_file} className={`fs-12 m-0 fs-smaller`}>
                                                                                                    <FileIcon className="p-1" />
                                                                                                    {file?.file_name}

                                                                                                </a>

                                                                                                <p className={`fs-12 ms-md-2 m-0 fs-smaller`}>
                                                                                                    {file?.file_date}
                                                                                                </p>

                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </del>
                                                                                <p className="ms-md-3 fs-12 m-0 fs-smaller">deleted by : {file?.delete_user?.name}</p>
                                                                            </div>
                                                                            :
                                                                            <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap">
                                                                                {
                                                                                    file?.comment ?
                                                                                        <>
                                                                                            <p className={`fs-12 m-0 fs-smaller`}>

                                                                                                {file?.comment}
                                                                                            </p>
                                                                                            <p className={`fs-12 m-0 fs-smaller ms-md-1`}>
                                                                                                {file?.file_date}
                                                                                            </p>

                                                                                            <a target='_blank' className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                                                <FileIcon className="p-1" />
                                                                                                {file?.file_name}
                                                                                            </a>

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>


                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <h5 className="py-3 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-logs-yet']}</h5>
                                    }
                                </div>
                            </div> */}

                            {/* <div className="col-12">
                                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                    {
                                        returnedFiles?.length > 0 ?
                                            returnedFiles?.map((file, index) => (
                                                index < 5 &&
                                                <div key={index} className="col-12">
                                                    <div className="row pb-3">
                                                        <div className="col-auto position-relative">
                                                            <div className="blue-dot-logs" />
                                                            {
                                                                returnedFiles?.length !== index + 1 && index + 1 < 5 &&
                                                                <div className="blue-dot-logs-lines" ></div>

                                                            }
                                                        </div>
                                                        <div className="col ps-0">
                                                            <div className="row">
                                                                <div className="col">
                                                                    {
                                                                        file?.deleted_at ?
                                                                            <div className="d-flex align-items-center">
                                                                                <del>

                                                                                    {
                                                                                        file?.comment ?
                                                                                            <div className="d-flex align-items-center">
                                                                                                <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                    {file?.comment}
                                                                                                </p>
                                                                                                &nbsp;

                                                                                                <a target='_blank' href={file?.full_path_file} className={`fs-12 m-0 fs-smaller`}>
                                                                                                    <FileIcon className="p-1" />
                                                                                                    {file?.file_name}

                                                                                                </a>
                                                                                                &nbsp;

                                                                                                <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                    {file?.file_date}
                                                                                                </p>

                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </del>
                                                                                <p className="ms-3 fs-12 m-0 fs-smaller">deleted by : {file?.delete_user?.name}</p>
                                                                            </div>
                                                                            :
                                                                            <div className="d-flex align-items-center">
                                                                                {
                                                                                    file?.comment ?
                                                                                        <>
                                                                                            <p className={`fs-12 m-0 fs-smaller`}>

                                                                                                {file?.comment}
                                                                                            </p>
                                                                                            &nbsp;
                                                                                            <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                {file?.file_date}
                                                                                            </p>
                                                                                            &nbsp;

                                                                                            <a target='_blank' className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                                                <FileIcon className="p-1" />
                                                                                                {file?.file_name}
                                                                                            </a>

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>


                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <h5 className="py-3 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-logs-yet']}</h5>
                                    }
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                    //delete popup
                    deletePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => cancelDeletePopup()} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={() => deleteElement()} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => cancelDeletePopup()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    // upload profile picture popup
                    uploadImagePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => { e.preventDefault(); setUploadImagePopup(false); setTempPicture(''); setProfilePicture(''); setZoom(1); }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 pt-2 color-dark-blue">Profile Picture</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center py-3">
                                                {
                                                    (formData?.picture || tempPicture) && tempPicture ?
                                                        <div className="image-crop-custom-containter">
                                                            <Cropper
                                                                image={tempPicture ? tempPicture : (profileClient?.picture ? profileClient?.full_path_picture : null)}
                                                                crop={crop}
                                                                accept={'jpg,jpeg'}
                                                                zoom={zoom}
                                                                maxZoom={10}
                                                                cropShape={'round'}
                                                                aspect={1 / 1}
                                                                onCropChange={(e) => setCrop(e)}
                                                                onCropComplete={onCropComplete}
                                                                onZoomChange={setZoom}
                                                                showGrid={true}
                                                            />
                                                        </div>
                                                        :
                                                        <img className="popup-profile-image" src={formData?.picture ? formData?.full_path_picture : generalData?.fixed_settings?.full_path_no_ngo_picture} alt="profile" />
                                                }

                                            </div>

                                            <form>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <input hidden accept="image/*" className="upload-comment-files" id='upload-comment-files' type="file" onChange={(e) => { e.preventDefault(); e.stopPropagation(); profilePictureHandler(e.target.files) }} />
                                                    <label htmlFor="upload-comment-files" >
                                                        <p className="popup-button my-auto mx-2">{profilePicture || profileClient?.picture ? 'Edit' : 'Add'}</p>
                                                    </label>
                                                    {
                                                        formData?.picture ?
                                                            <button onClick={(e) => removePicture(e)} type="button" className="popup-button my-auto mx-2">Remove</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        tempPicture ?
                                                            <button onClick={(e) => showCroppedImage()} type="button" className="popup-button my-auto mx-2">Save</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
            <Api options={usersApiOptions} />
            <Api options={generalDataApiOptions} />
        </>
    )
}